import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getInActiveContainer } from "services/containerService";

function InactiveContainer() {
  useEffect(async () => {
    await fetchInactiveContainerDetails();
  }, []);

  const [inactiveContainer, setInactiveContainer] = useState("");

  const fetchInactiveContainerDetails = () => {
    getInActiveContainer().then((Response) => {
      setInactiveContainer(Response);
      console.log(Response);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const inactiveContainersData = {
    columns: [
      // { Header: "Container Key", accessor: "containerKey", },
      { Header: "Container Name", accessor: "name", align: "center" },
      // { Header: "Container Name", accessor: "name",  Cell: (cell) => (
      //   <VuiTypography variant="gradient" fontWeight="medium" color="white" component="p" style={{width: "135px"}}>{cell.row.values.name}</VuiTypography>
      // )  },
      { Header: "Capacity", accessor: "capacity", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      {
        Header: "Year Build",
        accessor: "yearBuild",
        align: "center",
        Cell: (cell) => (
          <VuiTypography variant="sm" color="text">
            {dates(cell.row.values.yearBuild)}
          </VuiTypography>
        ),
      },
      {
        Header: "Activate LCL",
        align: "center",
        accessor: "containerKey",
        Cell: (cell) => (
          <Link to={`/merchant/activate-lcl-container/${cell.row.values.containerKey}`}>
            <VuiButton variant="gradient" color="info" size="medium">
              Activate LCL
            </VuiButton>
          </Link>
        ),
      },
      {
        Header: "Activate FCL",
        align: "center",
        Cell: (cell) => (
          <Link to={`/merchant/activate-fcl-container/${cell.row.values.containerKey}`}>
            <VuiButton variant="gradient" color="warning" size="medium">
              Activate FCL
            </VuiButton>
          </Link>
        ),
      },
    ],

    rows: inactiveContainer ? inactiveContainer : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              List of Inactive container
            </VuiTypography>
          </VuiBox>
          <DataTable fontWeight="medium" color="white" table={inactiveContainersData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InactiveContainer;
