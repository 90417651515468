const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export async function getAllUsers() {
  try {
    const response = await axios.get("/api/users");
    console.log("response  ", response);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function createUser(data) {
  try {
    const response = await axios.post(`${baseUrl}/user/auth/register`, data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function verifyUser(token) {
  try {
    const response = await axios.get(`${baseUrl}/user/auth/verify/${token}`);
    return response.data;
  } catch (e) {
    return e.response;
  }
}

// export async function loginUser(token) {
//     try{
//         const response = await axios.get(`${baseUrl}/user/auth/verify/${token}`);
//         return response.data;
//     }
//     catch(e){
//         return e.response;
//     }
// }

export async function login(userData) {
  console.log("login called", userData);
  try {
    const res = await axios.post(`${baseUrl}/user/auth/login`, userData);
    const { token } = res.data;

    localStorage.setItem("jwtToken", token);
    //Set token on Header request
    setAuthToken(token);
    const decodedUser = jwt_decode(token);
    console.log("decode use", decodedUser);
    return res;
  } catch (err) {
    if (err.message === "Network Error") {
      swal("Server is not responding", "Try again!", "error");
    }
    if (err.response.status === 500) {
      swal("Something went wrong", "try again!", "error");
    }
    if (err.response) {
      const errors = [];
      if (err.response && err.response.data.msg.includes("User")) {
        errors.push(["User", err.response.data.msg]);
      }
      if (err.response && err.response.data.msg.includes("password")) {
        errors.push(["password", err.response.data.msg]);
      }
      if (err.response && err.response.data.msg.includes("verify")) {
        swal("Please verify your email address first", "Thanks!", "error");
      }
    }
    return err.response;
  }
}

export async function setCurrentUser(decodedUser) {
  return {
    type: SET_CURRENT_USER,
    payload: decodedUser,
  };
}

export async function logoutUser() {
  //Remove Token from Local Storage
  localStorage.removeItem("jwtToken");

  //Remove Token from Request
  setAuthToken(false);
}

export async function getRequestForm() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/quickRequest`);
    console.log("testing????????????????????????????>>>>>", response);
    return response.data;
  } catch (err) {
    return err.response;
  }
}
