

import colors from "assets/theme/base/colors";

//theme colors
const { info } = colors;
const { charts } = info;

export const pieChartOptions = {
  labels: ["Dev.to", "BitNautic", "Github"],
  colors: [charts[100], charts[600], charts[300]],
  chart: {
    width: "100%",
    stroke: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  stroke: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: [charts[100], charts[600], charts[300]],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export const pieChartData = [70, 10, 20];
