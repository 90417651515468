import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getShipPendingQuoatation } from "services/quoatationService";
import { getUserDetails } from "services/shipService";
import { updateInvoiceLclContainerStatus } from "services/containerService";

function approveLCLRequest(props) {

    let history = useHistory();
    const [userData, setuserData] = useState({})
    const [requestData, setRequestData] = useState(props.location.details)

  useEffect(() => {
      console.log(">>>>>>>>>>>>>>>> userId",props.location.details)
      handleGetUserData(props.location.details.shipperID)
  },[])

  const handleGetUserData = (id) => {
    getUserDetails(id).then(response => {
     console.log("userData",response.data);
     setuserData(response.data);
    })
  }

  const handleApproveContainerShip = () => {
      console.log(">>>>>>>>>>>>>???????????? approver container",{invoiceID:requestData.invoiceID ,containerKey: requestData.containerKey ,status:"Approved"})
    updateInvoiceLclContainerStatus({invoiceID:requestData.invoiceID ,containerKey: requestData.containerKey ,status:"Approved"}).then(response => {
      handleSuccess()
    })

    // success
   
  };

  const handleSuccess = () => {
    swal("", " Success ! Ship MMSIKY = 7788 is booked successfully Thanks!", "success")
    .then(()=>{
      window.location = '/merchant/lcl-container-pending-quote';
    });
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <VuiBox mb={3} p={1}>
                <VuiTypography
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="white"
                >
                  Lcl Container Details
                </VuiTypography>
              </VuiBox>
            </Grid>
            <div style={{display:"grid", gridTemplateColumns: "33.33% 33.33% 33.33%", columnGap: "10px", width: "100%"}}>
            <div >
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                        Name
                    </VuiTypography>
                </VuiBox>
                <VuiInput
                inputProps={{ type: "string" }}
                disabled={true}
                defaultValue={"value"}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={userData.name} 
                />
            </div>
            <div item xs={12} lg={3}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                    Phone
                    </VuiTypography>
                </VuiBox>
                <VuiInput
                inputProps={{ type: "string" }}
                disabled={true}
                defaultValue={"value"}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={userData.phone} 
                />
            </div>
            <div >
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                    Country
                    </VuiTypography>
                </VuiBox>
                <VuiInput
                inputProps={{ type: "string" }}
                disabled={true}
                defaultValue={"value"}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={userData.country} 
                />
            </div>
            <div item xs={12} lg={3} style={{marginRight:"10px"}}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                    City
                    </VuiTypography>
                </VuiBox>
                <VuiInput
                inputProps={{ type: "string" }}
                disabled={true}
                defaultValue={"value"}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={userData.city} 
                />
            </div>
            <div item xs={12} lg={3}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                    State
                    </VuiTypography>
                </VuiBox>
                <VuiInput
                inputProps={{ type: "string" }}
                disabled={true}
                defaultValue={"value"}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={userData.state} 
                />
            </div>
            </div>
            <div style={{display: "flex", alignItems:"center", justifyContent: "center", marginTop:"10px"}}>
                <VuiButton 
                    variant="gradient" 
                    color="info" 
                    size="medium"
                    onClick={handleApproveContainerShip}
                    >Approve Request</VuiButton>
            </div>
          </Grid>

        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default approveLCLRequest;
