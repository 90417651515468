import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data
import selectData from "layouts/pages/ship/add-ship/data/selectData";
import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";

import { createShip } from "services/shipService";
import { getPorts } from "services/shipService";

function AddShip() {
  const [errorMsg, setErrorMsg] = useState({
    mmsiKey: { errorMsg: "" },
    imo: { errorMsg: "" },
    name: { errorMsg: "" },
    yearBuild: { errorMsg: "" },
    homePort: { errorMsg: "" },
    callSign: { errorMsg: "" },
    shipFlag: { errorMsg: "" },
    deadWeight: { errorMsg: "" },
    capacity: { errorMsg: "" },
    shipType: { errorMsg: "" },
    standard20: { errorMsg: "" },
    standard40: { errorMsg: "" },
    highcube40: { errorMsg: "" },
    higcube45: { errorMsg: "" },
    refrigerated20: { errorMsg: "" },
    refrigerated40: { errorMsg: "" },
  });
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [mmsiKey, setMmsiKey] = useState("");
  const [imo, setImo] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [yearBuild, setYearBuild] = useState("");
  const [homePort, setHomePort] = useState("");
  const [callSign, setCallSign] = useState("");
  const [shipFlag, setShipFlag] = useState("");
  const [deadWeight, setDeadWeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [shipType, setShipType] = useState("");
  // const [containerType, setContainerType] = useState("");
  const [standard20, setStandard20] = useState("");
  const [standard40, setStandard40] = useState("");
  const [highcube40, setHighcube40] = useState("");
  const [refrigerated20, setRefrigerated20] = useState("");
  const [refrigerated40, setRefrigerated40] = useState("");
  const [higcube45, setHigcube45] = useState("");
  // const [weightMT, setWeightMT] = useState("");
  // const [volumeM, setVolumeM] = useState("");
  // const [grossWeightMT, setGrossWeightMT] = useState("");

  function handleDeposit() {
    console.log({
      mmsiKey: mmsiKey,
      imo: imo,
      name: name,
      image: image,
      yearBuild: yearBuild,
      homePort: homePort,
      callSign: callSign,
      shipFlag: shipFlag,
      deadWeight: deadWeight,
      capacity: capacity,
      shipType: shipType,
      standard20: standard20,
      standard40: standard40,
      highcube40: highcube40,
      refrigerated20: refrigerated20,
      refrigerated40: refrigerated40,
      higcube45: higcube45,
    });

    let errorLengthMmsiKey = customValidations("mmsiKey", mmsiKey);
    let errorLengthImo = customValidations("imo", imo);
    let errorLengthName = customValidations("name", name);
    let errorLengthYearBuild = customValidations("yearBuild", yearBuild);
    let errorLengthHomePort = customValidations("homePort", homePort);
    let errorLengthCallSign = customValidations("callSign", callSign);
    let errorLengthShipFlag = customValidations("shipFlag", shipFlag);
    let errorLengthDeadWeight = customValidations("deadWeight", deadWeight);
    let errorLengthCapacity = customValidations("capacity", capacity);
    let errorLengthShipType = customValidations("shipType", shipType);
    let errorLengthStandard20 = customValidations("standard20", standard20);
    let errorLengthStandard40 = customValidations("standard40", standard40);
    let errorLengthHighcube40 = customValidations("highcube40", highcube40);
    let errorLengthHigcube45 = customValidations("higcube45", higcube45);
    let errorLengthRefrigerated20 = customValidations("refrigerated20", refrigerated20);
    let errorLengthRefrigerated40 = customValidations("refrigerated40", refrigerated40);

    if (
      errorLengthMmsiKey !== 1 &&
      errorLengthImo !== 1 &&
      errorLengthName !== 1 &&
      errorLengthYearBuild !== 1 &&
      errorLengthHomePort !== 1 &&
      errorLengthCallSign !== 1 &&
      errorLengthShipFlag !== 1 &&
      errorLengthDeadWeight !== 1 &&
      errorLengthCapacity !== 1 &&
      errorLengthShipType !== 1 &&
      errorLengthStandard20 !== 1 &&
      errorLengthStandard40 !== 1 &&
      errorLengthHighcube40 !== 1 &&
      errorLengthHigcube45 !== 1 &&
      errorLengthRefrigerated20 !== 1 &&
      errorLengthRefrigerated40 !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(
        errorLengthMmsiKey,
        errorLengthImo,
        errorLengthName,
        errorLengthYearBuild,
        errorLengthHomePort,
        errorLengthCallSign,
        errorLengthShipFlag,
        errorLengthDeadWeight,
        errorLengthCapacity,
        errorLengthShipType,
        errorLengthStandard20,
        errorLengthStandard40,
        errorLengthHighcube40,
        errorLengthHigcube45,
        errorLengthRefrigerated20,
        errorLengthRefrigerated40,
        "input errrrrrorrrr"
      );

      return;
    }

    const userData = {
      mmsiKey: mmsiKey,
      imo: imo,
      name: name,
      image: "",
      yearBuild: yearBuild,
      homePort: homePort,
      callSign: callSign,
      shipFlag: shipFlag,
      deadWeight: deadWeight,
      capacity: capacity,
      shipType: shipType,
      standard20: standard20,
      standard40: standard40,
      highcube40: highcube40,
      refrigerated20: refrigerated20,
      refrigerated40: refrigerated40,
      higcube45: higcube45,
      weightMT: 0,
      volumeM: 0,
      grossWeightMT: 0,
      routeShip: [],
    };
    createShip(userData).then((response) => {
      console.log(response);
      if (response.msg.includes("successfully")) {
        swal("Great!", " Ship added successfully", "success").then(() => {
          window.location = "#/merchant/inactive-ships";
        });
      } else if (response.msg.includes("already")) {
        swal(``, `Ship with Mmsi Key ${mmsiKey} already exists`, `error`);
      }
    });
  }

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = data.portName;
          return data;
        });
        // console.log(resp, "resp");
        setSearchedPorts(resp);
        // console.log(searchedPorts, "searchedPorts");
      });
    }
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.addShipValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    customValidations(name, value);

    if (name === "mmsiKey") {
      setMmsiKey(value);
    } else if (name === "imo") {
      setImo(value);
    } else if (name === "name") {
      setName(value);
    } else if (name === "yearBuild") {
      console.log(value);
      setYearBuild(value);
    } else if (name === "homePort") {
      setHomePort(value);
    } else if (name === "callSign") {
      setCallSign(value);
    } else if (name === "shipFlag") {
      setShipFlag(value);
    } else if (name === "deadWeight") {
      setDeadWeight(parseFloat(value));
    } else if (name === "capacity") {
      setCapacity(parseFloat(value));
    } else if (name === "shipType") {
      setShipType(value);
    } else if (name === "standard20") {
      setStandard20(value);
    } else if (name === "standard40") {
      setStandard40(value);
    } else if (name === "highcube40") {
      setHighcube40(value);
    } else if (name === "higcube45") {
      setHigcube45(value);
    } else if (name === "refrigerated20") {
      setRefrigerated20(value);
    } else if (name === "refrigerated40") {
      setRefrigerated40(value);
    } else {
      console.log("ERRRORRRRRRRRR");
    }
  };

  console.log(homePort);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Add Ship
          </VuiTypography>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  MMSI Key
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                //   border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                // })}
                // required={true}
                value={mmsiKey}
                name="mmsiKey"
                error={errorMsg.mmsiKey.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.mmsiKey.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  IMO
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required
                value={imo}
                name="imo"
                error={errorMsg.imo.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.imo.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required
                value={name}
                name="name"
                error={errorMsg.name.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />

              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.name.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Year Build
              </VuiTypography>
              <VuiDatePicker
                name="yearBuild"
                label="Year Build"
                options={{ dateFormat: "Y-m-d" }}
                // input={{ placeholder: "Select a Build Year" }}
                error={errorMsg.yearBuild.errorMsg.length > 0}
                value={yearBuild}
                onChange={(e) => handleChangeInput("yearBuild", e[0])}
                // onChange={(e) => setYearBuild(e[0])}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.yearBuild.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <FormField 
            label="Home Port" 
            placeholder="Add City, Port, Country"
            value={homePort} 
            onChange={e => setHomePort(e.target.value)}
             /> */}
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                HomePort
              </VuiTypography>
              <VuiSelect
                name="homePort"
                placeholder="Select Destination Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.homePort.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("homePort", e.value)}
                // onChange={(e) => setHomePort(e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.homePort.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Call Sign
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={""}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={callSign}
                name="callSign"
                error={errorMsg.callSign.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.callSign.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Ship Flag
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={shipFlag}
                name="shipFlag"
                error={errorMsg.shipFlag.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.shipFlag.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Dead Weight
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={deadWeight}
                name="deadWeight"
                error={errorMsg.deadWeight.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                // onChange={(e) => setDeadWeight(parseFloat(e.target.value))}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.deadWeight.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Capacity
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={0}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={capacity}
                name="capacity"
                error={errorMsg.capacity.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                // onChange={(e) => setCapacity(parseFloat(e.target.value))}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.capacity.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <VuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <VuiTypography
                    component="label"
                    variant="caption"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Ship Type
                  </VuiTypography>
                </VuiBox>
                <VuiSelect
                  placeholder="Select Ship Type"
                  options={selectData.ship}
                  value={selectData.ship.value}
                  name="shipType"
                  error={errorMsg.shipType.errorMsg.length > 0}
                  onChange={(e) => handleChangeInput("shipType", e.value)}
                  // onChange={(e) => setShipType(e.value)}
                />
              </VuiBox>
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.shipType.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Container Type
                </VuiTypography>
              </VuiBox>
            </Grid>

            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Ship Standard-20
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={standard20}
                name="standard20"
                error={errorMsg.standard20.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.standard20.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  standard40
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={standard40}
                name="standard40"
                error={errorMsg.standard40.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.standard40.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Highcube40
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={highcube40}
                name="highcube40"
                error={errorMsg.highcube40.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.highcube40.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  High Cube-45
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={higcube45}
                name="higcube45"
                error={errorMsg.higcube45.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.higcube45.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Refrigerated20
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={refrigerated20}
                name="refrigerated20"
                error={errorMsg.refrigerated20.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.refrigerated20.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={4} lg={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  refrigerated40
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={refrigerated40}
                name="refrigerated40"
                error={errorMsg.refrigerated40.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.refrigerated40.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiButton
                onClick={handleDeposit}
                variant="gradient"
                color="info"
                sx={{ minWidth: "100px" }}
              >
                Submit
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  );
}

export default AddShip;
