

import { useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiTagInput from "components/VuiTagInput";
import VuiDatePicker from "components/VuiDatePicker";
import { IoMdCube } from "react-icons/io";

import VuiButton from "components/VuiButton";
import {trasportData} from 'services/logisticsService';

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";



function BasicInfo() {
  const [skills, setSkills] = useState(["react", "angular"]);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");

  
  function handleDeposit() {
    const userData = {
      "portoforgin": origin,
      "portofdestination": destination,
      "Typeofshipment": FCL
    }

    trasportData(userData).then(response => {
      if(response.success){
        swal("Great!", " You have successfully registered! \n Check your email for verification", "success");
      } else {
        swal("",response.message, "error");
      }
    })
  }


  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Basic Info
        </VuiTypography>
      </VuiBox>
      <VuiBox component="form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField 
              label="Origin Of Shipment" 
              placeholder="City,Port,Country"
              value={origin}  
              name="origin" 
              onChange={e => setOrigin(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
             label="Destination Of Shipment"
              placeholder="City,Port,Country" 
              value={destination}  
              name="destination" 
              onChange={e => setDestination(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} sm={2}>
                <VuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      color="white"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Type Of Shipment
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect placeholder="FCL" options={selectData.gender} />
                </VuiBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            <VuiTypography  variant="button"color="white" fontWeight="regular">
               In review
            </VuiTypography>
            <VuiDatePicker label= "in review" input={{ placeholder: "Select a date" }} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VuiButton
             label="last name" 
             variant="gradient"
             color="info"
             onClick={handleDeposit}
             >Button
             </VuiButton>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default BasicInfo;
