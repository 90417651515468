const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function createContainer(containerData) {
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/container/addContainer`,
      containerData
    );
    return response.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;

    // if (err.message === "Network Error") {
    //     swal("Server is not responding", "Try again!", "error");

    // }
    // if (err.response) {

    //     const errors = []
    //     if (err.response && err.response.data.msg.includes('already')) {
    //         swal(`Container for KEY = ${container.containerKey} is already exist`, "Try again!", "error");
    //     }
    //     if (err.response && err.response.data.msg.includes('Key')) {

    //         errors.push(['Key', err.response.data.msg])
    //     }

    //     if (err.response && err.response.data.msg.includes('record')) {
    //         swal("Something went wrong", "Try again!", "error");
    //     }
    //     if (err.response && err.response.data.msg.includes('fail')) {
    //         swal("Container is not added due to internel issue", "Please Try again!", "error");
    //     }
    // }
  }
}

export async function getInActiveContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/serviceProvider/container/getInactiveContainersByUser`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getActiveContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/serviceProvider/container/getActiveContainersByUser`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function activateContainerLcl(containerInfo) {
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/container/activateLCLContainer`,
      containerInfo
    );
    return response.data;
  } catch (err) {
    if (err.message === "Network Error") {
      swal("Server is not responding", "Try again!", "error");
    }
    if (err.response) {
      const errors = [];
      if (err.response && err.response.data.msg.includes("record")) {
        swal("Something went wrong", "Try again!", "error");
      }
      if (err.response && err.response.data.msg.includes("status")) {
        swal("Problem in updating ship statud", "Try again!", "error");
      }
      if (err.response && err.response.data.msg.includes("fail")) {
        swal("Something went wrong in container activation", "Try again!", "error");
      }

      if (err.response && err.response.data.msg.includes("already")) {
        swal("Container is already activated", "Try again!", "error");
      }

      if (err.response && err.response.data.msg.includes("already")) {
        swal("Container is already activated", "Try again!", "error");
      }
    }
  }
}

export async function activateContainerFcl(containerInfo) {
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/container/activateFCLContainer`,
      containerInfo
    );
    return response.data;
  } catch (err) {
    if (err.message === "Network Error") {
      swal("Server is not responding", "Try again!", "error");
    }
    if (err.response) {
      const errors = [];
      if (err.response && err.response.data.msg.includes("record")) {
        swal("Something went wrong", "Try again!", "error");
      }
      if (err.response && err.response.data.msg.includes("status")) {
        swal("Problem in updating ship statud", "Try again!", "error");
      }
      if (err.response && err.response.data.msg.includes("fail")) {
        swal("Something went wrong in container activation", "Try again!", "error");
      }

      if (err.response && err.response.data.msg.includes("already")) {
        swal("Container is already activated", "Try again!", "error");
      }

      if (err.response && err.response.data.msg.includes("already")) {
        swal("Container is already activated", "Try again!", "error");
      }
    }
  }
}

export async function FclContainerQuoatation(SPID) {
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/container/getBookedFclContainerWithApprovedPaymentBySPID?SPID=${SPID}`
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
}

export async function FclContainerPendingQuoatation() {
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/container/getBookedFclContainerWithPendingPaymentBySPID`
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
}

export async function LclContainerQuoatation(SPID) {
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/container/getBookedLclContainerWithApprovedPaymentBySPID?SPID=${SPID}`
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
}

export async function LclContainerPendingQuoatation() {
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/container/getBookedLclContainerWithPendingPaymentBySPID`
    );
    console.log(">>>>>>>>>>>>>. response pendinf containrr", response);
    return response.data;
  } catch (e) {
    return e.response;
  }
}

export async function updateInvoiceLclContainerStatus(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  console.log(">>>>>>>>>>>>>>>>???", data);
  const response = await axios.post(
    `${baseUrl}/serviceProvider/container/updateInvoiceLclContainerStatus`,
    data
  );
  if (response) {
    return response;
  } else {
    swal("", response.message, "error");
  }
}

export async function updateInvoiceFclContainerStatus(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  console.log(">>>>>>>>>>>>>>>>???", data);
  const response = await axios.post(
    `${baseUrl}/serviceProvider/container/updateInvoiceFclContainerStatus`,
    data
  );
  if (response) {
    return response;
  } else {
    swal("", response.message, "error");
  }
}
