import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Alerts from "layouts/pages/alerts";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";

// Own Routes //

// auth //
// import SignIn from "layouts/pages/authentication/sign-in";
// import SignUp from "layouts/pages/authentication/sign-up";
// import userVerify from "layouts/pages/authentication/verify";

// dashboard //
import Dashboard from "layouts/pages/dashboards";

// ship //
import AddShip from "layouts/pages/ship/add-ship";
import ActiveShip from "layouts/pages/ship/active-ship";
import InActiveShip from "layouts/pages/ship/inactive-ship";
import SubmitRequest from "layouts/pages/support/submit-request";
import activateShip from "layouts/pages/ship/active-ship/activateShip";

// container //
import AddContainer from "layouts/pages/container/add-container";
import ActiveContainers from "layouts/pages/container/active-containers";
import InactiveContainers from "layouts/pages/container/inactive-containers";
import LCLContainerQuoatation from "layouts/pages/container/LCL-container-quoatation";
import LCLContainerPendingQuoatation from "layouts/pages/container/LCL-container-pending-quoatation";
import FCLContainerQuoatation from "layouts/pages/container/FCL-container-quoatation";
import FCLContainerPendingQuoatation from "layouts/pages/container/FCL-container-pending-quoatation";

// smart doc //
import ProfomaInvoice from "layouts/pages/smart-document/profoma-invoice";
import CommercialInvoice from "layouts/pages/smart-document/commercial-invoice";
import Quoatation from "layouts/pages/smart-document/quotation";
// import BillofLading from "layouts/pages/smart-document/bill-of-lading";
import PurchaseOrder from "layouts/pages/smart-document/purchase-order";

// quotation //
import CompletedQuote from "layouts/pages/quotation/completed-quote";
import FclQuote from "layouts/pages/quotation/fcl-quote";
import LclQuote from "layouts/pages/quotation/lcl-quote";
import PendingQuote from "layouts/pages/quotation/pending-quote";
import ShipQuoatation from "layouts/pages/quotation/ship-quoatation";
import ShipPendingQuoatation from "layouts/pages/quotation/ship-pending-quoatation";

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import {
  FaShoppingCart,
  FaShip,
  FaShippingFast,
  FaReceipt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import GetRequest from "layouts/pages/Get-Request";
import UpdateBillOfLading from "layouts/pages/smart-document/update-bill-of-lading";
import BillOfLading from "layouts/pages/smart-document/bill-of-lading";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboards",
    icon: <IoHome size="15px" color="inherit" />,
    collapse: [
      {
        name: "Dashboard",
        key: "Dashboard",
        route: "/merchant/dashboard",
        component: Dashboard,
      },
    ],
  },
  { type: "title", title: "Application", key: "title-pages" },
  {
    type: "collapse",
    name: "Ship",
    key: "ship",
    icon: <FaShip size="15px" color="inherit" />,
    collapse: [
      {
        name: "Add Ship",
        key: "add-ship",
        route: "/merchant/add-ship",
        component: AddShip,
      },
      {
        name: "Active Ships",
        key: "active-ship",
        route: "/merchant/active-ships",
        component: ActiveShip,
      },
      {
        name: "Inactive Ships",
        key: "inactive-ships",
        route: "/merchant/inactive-ships",
        component: InActiveShip,
      },
    ],
  },
  {
    type: "collapse",
    name: "Container",
    key: "container",
    icon: <FaShippingFast size="15px" color="inherit" />,
    collapse: [
      {
        name: "Add Container",
        key: "add-container",
        route: "/merchant/add-container",
        component: AddContainer,
      },
      {
        name: "Active Containers",
        key: "active-containers",
        route: "/merchant/active-containers",
        component: ActiveContainers,
      },
      {
        name: "Inactive Containers",
        key: "inactive-containers",
        route: "/merchant/inactive-containers",
        component: InactiveContainers,
      },
    ],
  },

  {
    type: "collapse",
    name: "Smart Documents",
    key: "smart-documents",
    icon: <FaFileInvoiceDollar size="15px" color="inherit" />,
    collapse: [
      {
        name: "Invoice",
        key: "invoice",
        collapse: [
          {
            name: "Proforma Invoice",
            key: "profoma-invoice",
            route: "/merchant/profoma-invoice",
            component: ProfomaInvoice,
          },
          {
            name: "Commercial Invoice",
            key: "commercial-invoice",
            route: "/merchant/commercial-invoice",
            component: CommercialInvoice,
          },
        ],
      },
      {
        name: "Quotation",
        key: "quotation",
        route: "/merchant/quoatation",
        component: Quoatation,
      },
      {
        name: "Bill of Lading",
        key: "bill-of-lading",
        route: "/merchant/bill-of-lading",
        component: UpdateBillOfLading,
      },
      // {
      //   name: "Update Bill of Lading",
      //   key: "update-bill-of-lading",
      //   route: "/merchant/update-bill-of-lading",
      //   component: UpdateBillOfLading,
      // },
      {
        name: "Purchase Order",
        key: "purchase-order",
        route: "/merchant/purchase-order",
        component: PurchaseOrder,
      },
    ],
  },

  {
    type: "collapse",
    name: "Quotation",
    key: "authentication",
    icon: <FaReceipt size="15px" color="inherit" />,
    collapse: [
      {
        name: "Ship Quote",
        key: "ship-quote",
        collapse: [
          // {
          //   name: "Pending",
          //   key: "Pending",
          //   route: "/merchant/pending-quote",
          //   component: PendingQuote,
          // },

          {
            name: "Ship Quotation",
            key: "ship-quote",
            route: "/merchant/ship-quote",
            component: ShipQuoatation,
          },
          {
            name: "Ship Pending Quotation",
            key: "ship-pending-quote",
            route: "/merchant/ship-pending-quote",
            component: ShipPendingQuoatation,
          },
        ],
      },

      {
        name: "Container Quotation",
        key: "Container-Quoatation",
        collapse: [
          {
            name: "LCL Container Quotation",
            key: "lcl-container-quoatation",
            route: "/merchant/lcl-container-quote",
            component: LCLContainerQuoatation,
          },
          {
            name: "FCL Container Quotation",
            key: "fcl-container-quoatation",
            route: "/merchant/fcl-container-quote",
            component: FCLContainerQuoatation,
          },
        ],
      },

      {
        name: "Container Pending Quotation",
        key: "Container-Pending-Quoatation",
        collapse: [
          {
            name: "LCL Container Pending Quotation",
            key: "lcl-container-pending-quoatation",
            route: "/merchant/lcl-container-pending-quote",
            component: LCLContainerPendingQuoatation,
          },
          {
            name: "FCL Container Pending Quotation",
            key: "lcl-container-pending-quoatation",
            route: "/merchant/fcl-container-pending-quote",
            component: FCLContainerPendingQuoatation,
          },
        ],
      },

      // {
      //   name: "Container Quote",
      //   key: "container-quote",
      //   collapse: [
      //     // {
      //     //   name: "LCL Quote",
      //     //   key: "lcl-quote",
      //     //   route: "/merchant/fcl-quote",
      //     //   component: FclQuote,
      //     // },
      //     // {
      //     //   name: "FCL Quote",
      //     //   key: "fcl-quote",
      //     //   route: "/merchant/lcl-quote",
      //     //   component: LclQuote,
      //     // },
      //   ],
      // },
    ],
  },

  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <IoCallSharp size="15px" color="inherit" />,
    collapse: [
      {
        name: "Submit Request",
        key: "Submit-Request",
        route: "/merchant/submit-request",
        component: SubmitRequest,
      },
      {
        name: "Get Request",
        key: "Get-Request",
        route: "/merchant/get-request",
        component: GetRequest,
      },
    ],
  },
];

export default routes;
