import { useState, useEffect } from "react";

import { Link, useParams, Redirect } from "react-router-dom";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
import VuiTagInput from "components/VuiTagInput";
import VuiButton from "components/VuiButton";
import VuiDatePicker from "components/VuiDatePicker";
import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Service
import { createContainer, activateContainerFcl } from "services/containerService";
import { getPorts, getActiveShips } from "services/shipService";
import swal from "sweetalert";

function ActivateFclContainer() {
  const [errorMsg, setErrorMsg] = useState({
    category: { errorMsg: "" },
    currentPort: { errorMsg: "" },
    departureTime: { errorMsg: "" },
    arrivalTime: { errorMsg: "" },
    readyToLoad: { errorMsg: "" },
    destinationPort: { errorMsg: "" },

    fclPrice: { errorMsg: "" },

    chooseShipStopPorts: { errorMsg: "" },
  });
  const [category, setcategory] = useState("");
  const [originPort, setoriginPort] = useState("");
  const [DepartureTime, setDepartureTime] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [ArrivalTime, setArrivalTime] = useState("");
  const [readyToLoad, setReadyToLoad] = useState("");
  const [fclPrice, setFclPrice] = useState("");
  const [standard, setStandard] = useState("");
  const [ports, setContainerStopPorts] = useState([]);
  const [containerLocation, setcontainerLocation] = useState("");
  const [currentPort, setcurrentPort] = useState([]);
  const [shipMmsiKey, setshipMmsiKey] = useState("");
  const [searchActiveShip, setsearchActiveShip] = useState("");
  const [searchedPorts, setSearchedPorts] = useState([]);

  const { containerKey } = useParams();

  const handleActivateFclContainer = () => {
    let errorLengthCategory = customValidations("category", category);
    let errorLengthFclPrice = customValidations("fclPrice", fclPrice);

    if (errorLengthCategory !== 1 && errorLengthFclPrice !== 1) {
      console.log("all ok ");
    } else {
      console.log("input errrrrrorrrr");

      return;
    }

    let newReadyToload = readyToLoad.split(" ");
    newReadyToload = newReadyToload[0];
    const activateFClData = {
      category: category,
      originPort: setcurrentPort,
      departureTime: "" + DepartureTime.replace(/ /g, "T"),
      destinationPort: destinationPort[0],
      arrivalTime: "" + ArrivalTime.replace(/ /g, "T"),
      fclType: standard.value,
      fclPrice: fclPrice,
      ports: ports,
      containerLocation: containerLocation,
      readyToLoad: newReadyToload,
      currentPort: currentPort[0],
      status: "Ready to Load",
      shipMmsiKey: shipMmsiKey.value,
      containerKey: containerKey,
      containerStatus: "active",
      bookingType: "FCL",
    };

    console.log("containerAcitvateInformation", activateFClData);

    const addContainer = activateContainerFcl(activateFClData).then((response) => {
      if (response.success == true) {
        swal("Great!", "Container Activated successfully", "success").then(() => {
          window.location = "#/merchant/active-conatiners";
        });
      } else {
        swal("", response.message, "error");
      }
    });
  };

  const getMultiports = (e) => {
    const portName = [currentPort[0]];
    const containerLocation = [currentPort[1]];
    const multiPort = e.map((data) => {
      portName.push(data.portName);
      containerLocation.push([data.xcoord, data.ycoord]);
    });
    portName.push(destinationPort[0]);
    containerLocation.push(destinationPort[1]);
    setContainerStopPorts(portName);
    setcontainerLocation(containerLocation);
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = [data.portName, [data.xcoord, data.ycoord]];
          return data;
        });
        setSearchedPorts(resp);
      });
    }
  };

  useEffect(async () => {
    await fetchActiveDetails();
  }, []);

  const fetchActiveDetails = () => {
    getActiveShips().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>> Data ");
      const resp = Response.map((data) => {
        data.label = `${data.name} (${data.mmsiKey})`;
        data.value = data.mmsiKey;
        return data;
      });
      setsearchActiveShip(resp);
    });
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.activateShipValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    customValidations(name, value);

    if (name === "category") {
      setcategory(value);
      // } else if (name === "currentPort") {
      //   setCurrentPort(value);
      // } else if (name === "departureTime") {
      //   setDepartureTime(value);
      // } else if (name === "arrivalTime") {
      //   setArrivalTime(value);
      // } else if (name === "readyToLoad") {
      //   setReadyToLoad(value);
      // } else if (name === "destinationPort") {
      //   setDestinationPort(value);
    } else if (name === "fclPrice") {
      setFclPrice(value);
    } else {
      console.log("ERRRORRRRRRRRR");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="Activate LCL Container" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Activate FCL Container
          </VuiTypography>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* <FormField
                label="Category"
                placeholder="Add Category"
                value={category}
                onChange={(e) => setcategory(e.target.value)}
              /> */}
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Category
              </VuiTypography>
              <VuiInput
                name="category"
                placeholder="Enter Category"
                value={category}
                error={errorMsg.category.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.category.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Current Port
              </VuiTypography>
              <VuiSelect
                placeholder="Select Current Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => setcurrentPort(e.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Ready to Load
              </VuiTypography>
              <VuiDatePicker
                label="Ready to Load"
                input={{ placeholder: "Select a date" }}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{ enableTime: true, minDate: new Date() }}
                onChange={(e) => setReadyToLoad(e[0].toMysqlFormat())}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Departure Time
              </VuiTypography>
              <VuiDatePicker
                label="Enter Departure Time"
                input={{ placeholder: "Select a date" }}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{ enableTime: true, minDate: new Date(readyToLoad) }}
                onChange={(e) => setDepartureTime(e[0].toMysqlFormat())}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Arrival Time for Destination Port
              </VuiTypography>
              <VuiDatePicker
                label="Enter Arrival Time"
                input={{ placeholder: "Select a date" }}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{ enableTime: true, minDate: new Date(DepartureTime) }}
                onChange={(e) => setArrivalTime(e[0].toMysqlFormat())}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Destination Port
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                size="medium"
                fontSize="bold"
                placeholder="Select Destination Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => setDestinationPort(e.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* <FormField
                label="FCL Price"
                placeholder="Enter FCL Price"
                inputProps={{ type: "number" }}
                value={fclPrice}
                onChange={(e) => setFclPrice(e.target.value)}
              /> */}
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Fcl Price
              </VuiTypography>
              <VuiInput
                name="fclPrice"
                placeholder="Enter Fcl Price"
                value={fclPrice}
                error={errorMsg.fclPrice.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.fclPrice.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Container Standard
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Container Standard"
                options={[
                  { value: "standard", label: "Standard" },
                  { value: "economy", label: "Economy" },
                ]}
                value={standard}
                defaultValue={{ value: "", label: "" }}
                onChange={(e) => setStandard(e)}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Choose Ship Stop ports
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Destination Port"
                isMulti
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => getMultiports(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Select Ship Key
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Ship Key"
                options={searchActiveShip}
                value={searchActiveShip.value}
                onChange={(e) => setshipMmsiKey(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} mt={"1rem"}>
              <VuiButton
                variant="gradient"
                color="info"
                size="large"
                fontSize="16px"
                onClick={handleActivateFclContainer}
              >
                Activate FCL Container
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  );
}

export default ActivateFclContainer;
