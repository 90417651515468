import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getActiveContainer } from "services/containerService";
import "../container.css";

function ActiveContainer() {
  let history = useHistory();
  useEffect(async () => {
    await fetchActiveContainerDetails();
  }, []);

  const [activeContainer, setActiveContainer] = useState("");

  const fetchActiveContainerDetails = () => {
    getActiveContainer().then((Response) => {
      setActiveContainer(Response);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const datesWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  const showContainerDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/merchant/active-container-details",
      details: data,
    });
  };

  const activeContainersData = {
    columns: [
      // { Header: "Container Key", accessor: "containerKey",width: "12.5%", align: "left" },
      { Header: "Container Name", accessor: "name", align: "center" },
      {
        Header: "Current Port",
        accessor: "currentPort",
        align: "center",
      },
      {
        Header: "Destination Port",
        accessor: "destinationPort",
        align: "center",
      },
      {
        Header: "Ready To Load",
        accessor: "readyToLoad",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.readyToLoad)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.readyToLoad)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Departure Time",
        accessor: "departureTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.departureTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.departureTime)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Arrival Time",
        accessor: "arrivalTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.arrivalTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.arrivalTime)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Price",
        accessor: `bookingType`,
        align: "center",
      },
      {
        Header: "Know More",
        align: "center",
        Cell: (cell) => (
          <VuiButton
            variant="gradient"
            color="info"
            onClick={() => showContainerDetail(cell.row.original)}
          >
            More Details
          </VuiButton>
        ),
      },
    ],

    rows: activeContainer ? activeContainer : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card className="active-container-datatable">
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              Active Container
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all Container which are ready to shipped.
            </VuiTypography>
          </VuiBox>
          <DataTable table={activeContainersData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ActiveContainer;
