

// @mui material components
// @mui material components
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import team1 from "assets/images/avatar1.png";
import team2 from "assets/images/avatar2.png";
import team3 from "assets/images/avatar3.png";
import team4 from "assets/images/avatar4.png";
// Images
import profile1 from "assets/images/profile-1.png";
import profile2 from "assets/images/profile-2.png";
import profile3 from "assets/images/profile-3.png";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
// import Header from "layouts/pages/profile/profile-overview/components/Header/index";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings/index";
import Welcome from "layouts/pages/profile/profile-overview/components/Welcome/index";
import CarInformations from "layouts/pages/profile/profile-overview/components/CarInformations/index";



////////////////

// Settings page components
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";


function Overview() {
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <VuiBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2}>
          {/* <Sidenav /> */}
        </Grid>
        <Grid item xs={12} lg={9}>
          <VuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
            </Grid>
          </VuiBox>
        </Grid>
      </Grid>
    </VuiBox>
    <Footer />
  </DashboardLayout>
  );
}

export default Overview;
