import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { getBillOfLading } from "services/billOfLadingService";

function UpdateBillOfLading() {
  let history = useHistory();
  useEffect(async () => {
    await fetchBillOfLading();
  }, []);

  const [billOfLading, setBillOfLading] = useState("");

  const fetchBillOfLading = () => {
    getBillOfLading().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> bill of lading", Response);
      setBillOfLading(Response);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const datesWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  // const showShipDetail = (data) => {
  //   console.log(data);
  //   history.push({
  //     pathname: "/merchant/active-ship-details",
  //     details: data,
  //   });
  // };

  const upload = () => {
    swal("", "Upload Initiated...", "success");
  };

  const billTable = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey"},
      // { Header: "IMO", accessor: "imo"},
      {
        Header: "Bill ID",
        accessor: "id",
        align: "center",
      },
      {
        Header: "Date",
        accessor: "date",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.date)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Place of Reciept",
        accessor: "place_of_reciept",
        align: "center",
      },
      {
        Header: "Place of Delivery",
        accessor: "place_of_delivery",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //   </>
        // ),
      },
      {
        Header: "Transport",
        accessor: "transport",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.departureTime)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.departureTime)}
        //     </VuiTypography>
        //   </>
        // ),
      },

      {
        Header: "Action",
        accessor: "action",
        align: "center",
        Cell: (cell) => {
          return (
            <VuiButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => upload(cell.row.original)}
            >
              Upload on BlockChain
            </VuiButton>
          );
        },
      },
    ],

    rows: billOfLading ? billOfLading : [],
  };

  const addNewBillOfLading = () => {
    console.log();
    history.push({
      pathname: "/merchant/BillOfLading",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            <VuiButton
              variant="gradient"
              component="span"
              color="info"
              onClick={addNewBillOfLading}
            >
              Add New Bill of Lading
            </VuiButton>
          </VuiBox>

          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              Bill of Lading
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all Bills of Lading.
            </VuiTypography>
          </VuiBox>
          <DataTable table={billTable} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateBillOfLading;
