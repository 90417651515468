import { useState } from "react";

// react-router-dom components
import { Link, Redirect } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import validator from "validator";
import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";

// Toast Imports //
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin.png";
import { login } from "services/userService";

import { useDispatch } from "react-redux";
import { signIn } from "../../../../store/users/actions";

function SignIn() {
  let dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState({
    password: { errorMsg: "" },
    email: { errorMsg: "" },
  });

  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.signValidation[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleLoginData = (name, value) => {
    console.log(name, value);
    customValidations(name, value);
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = () => {
    console.log({ email: email, password: password });

    let errorLengthEmail = customValidations("email", email);
    let errorLengthPassword = customValidations("password", password);

    if (errorLengthEmail !== 1 && errorLengthPassword !== 1) {
      console.log("Alll okkkkkkk");
    } else {
      console.log(errorLengthEmail, errorLengthPassword, "passord errrrorrrrr");
      return;
    }

    // if (password == "" || email == "") {
    //   swal("", "All Fields are required", "error");
    //   return;
    // }
    // if (!validator.isEmail(email)) {
    //   swal("", "Enter a correct email address", "error");
    //   return;
    // }
    var userData = {
      email: email.trim(),
      password: password,
      role: "Service Provider",
    };
    // dispatch(signIn(userData));
    login(userData).then((response) => {
      console.log(">>>>>>>>>>>>>>>>>>>>. response", response);
      if (response.status == 200 || response == undefined) {
        localStorage.setItem("jwtToken", response.data.token);
        console.log("response", response);
        window.location.href = "";

        //  } else {
        //     console.log("response", response);
        //     toast.error('🦄 Wrong Username and Password', {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pause OnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       });
      } else if (response.status === 400) {
        console.log("error login >>>>>>>..", response.data.msg);
        if (response.data.msg === "password not match") {
          swal("There was a problem", "Password is incorrect.", "error");
        } else if (response.data.msg === "User not exist") {
          swal("There was a problem", "We cannot find account with that email address.", "error");
        }

        return;
      }
    });
  };

  const handleShowPassword = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword(type);
  };

  return (
    <CoverLayout
      title="Nice to see you Merchant!"
      color="white"
      description="Enter your Email Address and Password to Login"
      image={bgImage}
      premotto={"INSPIRED BY THE FUTURE:"}
      motto={"DECENTRALIZED SHIPPING PLATFORM"}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Your email..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={email}
              name="email"
              error={errorMsg.email.errorMsg.length > 0}
              onChange={(e) => handleLoginData(e.target.name, e.target.value)}
              // onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
          <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
            {errorMsg.email.errorMsg}
          </VuiTypography>
        </VuiBox>

        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            {isShowPassword ? (
              <VuiInput
                type="password"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={password}
                name="password"
                error={errorMsg.password.errorMsg.length > 0}
                onChange={(e) => handleLoginData(e.target.name, e.target.value)}
                // onChange={(e) => setPassword(e.target.value)}
              />
            ) : (
              <VuiInput
                type="text"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={password}
                name="password"
                error={errorMsg.password.errorMsg.length > 0}
                onChange={(e) => handleLoginData(e.target.name, e.target.value)}
                // onChange={(e) => setPassword(e.target.value)}
              />
            )}
          </GradientBorder>
          <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
            {errorMsg.password.errorMsg}
          </VuiTypography>
          {isShowPassword ? (
            <p
              style={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: 500,
                color: "#0075ff",
                marginTop: "2px",
                fontFamily: "Plus Jakarta Display,Helvetica,Arial,sans-serif",
              }}
              onClick={() => handleShowPassword(false)}
            >
              Show Password
            </p>
          ) : (
            <p
              style={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: 500,
                color: "#0075ff",
                marginTop: "2px",
                fontFamily: "Plus Jakarta Display,Helvetica,Arial,sans-serif",
              }}
              onClick={() => handleShowPassword(true)}
            >
              Hide Password
            </p>
          )}
        </VuiBox>

        {/* <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox> */}
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={handleLogin}>
            SIGN IN
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Sign In / Sign Up with &nbsp;
            <a
              href="https://shipper.bitnautic.com/shipper/available-ship#/authentication/sign-up/cover"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Shipper
            </a>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
