import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { getActiveShips } from "services/shipService";

function fetchActiveShips() {
  let history = useHistory();
  useEffect(async () => {
    await fetchActiveDetails();
  }, []);

  const [activeShipData, setActiveShipData] = useState("");

  const fetchActiveDetails = () => {
    getActiveShips().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> active shipes", Response);
      setActiveShipData(Response);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const datesWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  const showShipDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/merchant/active-ship-details",
      details: data,
    });
  };

  const activeShipsData = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey"},
      // { Header: "IMO", accessor: "imo"},
      {
        Header: "Name",
        accessor: "name",
        align: "center",
      },
      {
        Header: "Current Port",
        accessor: "homePort",
        align: "center",
      },
      {
        Header: "Destination Port",
        accessor: "destinationPort",
        align: "center",
      },
      {
        Header: "Ready To Load",
        accessor: "readyToLoad",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.readyToLoad)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.readyToLoad)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Departure Time",
        accessor: "departureTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.departureTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.departureTime)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Arrival Time",
        accessor: "arrivalTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.arrivalTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.arrivalTime)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Price",
        accessor: `shipPrice`,
        align: "center",
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        Cell: (cell) => {
          return (
            <VuiButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => showShipDetail(cell.row.original)}
            >
              Read More
            </VuiButton>
          );
        },
      },
    ],

    rows: activeShipData ? activeShipData : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              Active Ships
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all ships which are ready to shipped.
            </VuiTypography>
          </VuiBox>
          <DataTable table={activeShipsData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default fetchActiveShips;
