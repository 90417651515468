import React from "react";
import { useState, useRef, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data

import { createShip } from "services/shipService";
import { getPorts } from "services/shipService";
import "./quotationInv.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import jsPDF from "jspdf";
import PDFDocument from "./quotationPdfdoc";
import SignaturePad from "react-signature-canvas";
import { PDFViewer } from "@react-pdf/renderer";
import { addBillOfLading } from "services/billOfLadingService";
import { getCompany, addCompany } from "services/billOfLadingService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Input = styled("input")({
  display: "none",
});

let currentDate = new Date();
currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;
console.log(">>>>>>>>>>>>>>> current date", currentDate);

function QuotationInvoice() {
  let stateRandomNumber = Math.random();
  const [showPreview, setShowPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState("");
  const [imgSrc, setImgsrc] = useState("");
  const [commonCompany, setCommonCompany] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [from, setFrom] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [quoteNumber, setQuoteNumber] = useState("");
  const [datee, setDatee] = useState(currentDate);
  const [buyerReference, setBuyerReference] = useState("");
  const [to, setTo] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [methodOfDispatch, setMethodOfDispatch] = useState("");
  const [typeOfShipment, setTypeOfShipment] = useState("");
  const [portOfLoading, setPortOfLoading] = useState("");
  const [portOfDischarge, setPortOfDischarge] = useState("");
  const [totalAmount, setTotalAmount] = useState("0");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [date, setDate] = useState(currentDate);
  const [signatoryCompany, setSignatoryCompany] = useState("");
  const [nameOfAuthorizedSignatory, setNameOfAuthorizedSignatory] = useState("");
  const [shipperDataTableValue, setShipperDataTableValue] = useState([
    {
      customId: stateRandomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQty: "",
      unitType: "",
      price: "",
      amount: "",
    },
  ]);
  const [shipperDataTable, setShipperDataTable] = useState([
    {
      productCode: (
        <VuiInput
          inputProps={{ type: "string" }}
          defaultValue={shipperDataTableValue[0].productCode}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].name}
          onChange={(e) => handleOnChangeCommodity("productCode", e.target.value, 1)}
        />
      ),
      descriptionOfGoods: (
        <VuiInput
          inputProps={{ type: "string" }}
          defaultValue={shipperDataTableValue[0].descriptionOfGoods}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].name}
          onChange={(e) => handleOnChangeCommodity("descriptionOfGoods", e.target.value, 1)}
        />
      ),
      unitQty: (
        <VuiInput
          inputProps={{ type: "number" }}
          defaultValue={shipperDataTableValue[0].unitQty}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].quantity}
          onChange={(e) => handleOnChangeCommodity("unitQty", e.target.value, 1)}
        />
      ),
      unitType: (
        <VuiInput
          inputProps={{ type: "number" }}
          defaultValue={shipperDataTableValue[0].unitType}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].unit}
          onChange={(e) => handleOnChangeCommodity("unitType", e.target.value, 1)}
        />
      ),
      price: (
        <VuiInput
          inputProps={{ type: "number" }}
          defaultValue={shipperDataTableValue[0].price}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].weight}
          onChange={(e) => handleOnChangeCommodity("price", e.target.value, 1)}
        />
      ),
      amount: (
        <VuiInput
          inputProps={{ type: "number" }}
          defaultValue={shipperDataTableValue[0].amount}
          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
          })}
          // value={commodityValue[0].description}
          onChange={(e) => handleOnChangeCommodity("amount", e.target.value, 1)}
        />
      ),
      remove: (
        <>
          <DeleteOutlineIcon
            fontSize="medium"
            className="quo-delete-icon-style"
            onClick={() => handleDeleteCommodity()}
          />
          {1}
        </>
      ),
    },
  ]);

  const [signature, setSignature] = useState("");
  const [dialogInformation, setDialogInformation] = useState({
    title: "",
    type: "from",
  });

  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [company, setCompany] = useState([]);

  const [tableData, setTableData] = useState([]);

  useEffect(async () => {
    await handleGetCompany();
  }, [shipperDataTableValue]);

  const hanldeAddDataTableRow = () => {
    let totalLength = shipperDataTableValue.length;
    let randomNumber = Math.random();
    console.log(">>>>>>>>>>>>>> totalLength", totalLength);
    shipperDataTableValue.push({
      customId: randomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQty: "",
      unitType: "",
      price: "",
      amount: "",
    });

    setShipperDataTableValue([...shipperDataTableValue]);
    // shipperDataTable.push({
    //   productCode: (
    //     <VuiInput
    //       inputProps={{ type: "string" }}
    //       defaultValue={shipperDataTableValue[totalLength].productCode}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Product Code"}
    //       // value={commodityValue[0].name}
    //       onChange={(e) => handleOnChangeCommodity("productCode", e.target.value, totalLength + 1)}
    //     />
    //   ),
    //   descriptionOfGoods: (
    //     <VuiInput
    //       inputProps={{ type: "string" }}
    //       defaultValue={shipperDataTableValue[totalLength].descriptionOfGoods}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Description of Goods"}
    //       // value={commodityValue[0].name}
    //       onChange={(e) =>
    //         handleOnChangeCommodity("descriptionOfGoods", e.target.value, totalLength + 1)
    //       }
    //     />
    //   ),
    //   unitQty: (
    //     <VuiInput
    //       inputProps={{ type: "number" }}
    //       defaultValue={shipperDataTableValue[totalLength].unitQty}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Unit Qty"}
    //       // value={commodityValue[0].quantity}
    //       onChange={(e) => handleOnChangeCommodity("unitQty", e.target.value, totalLength + 1)}
    //     />
    //   ),
    //   unitType: (
    //     <VuiInput
    //       inputProps={{ type: "number" }}
    //       defaultValue={shipperDataTableValue[totalLength].unitType}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Unit Type"}
    //       // value={commodityValue[0].unit}
    //       onChange={(e) => handleOnChangeCommodity("unitType", e.target.value, totalLength + 1)}
    //     />
    //   ),
    //   price: (
    //     <VuiInput
    //       inputProps={{ type: "number" }}
    //       defaultValue={shipperDataTableValue[totalLength].price}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Price"}
    //       // value={commodityValue[0].weight}
    //       onChange={(e) => handleOnChangeCommodity("price", e.target.value, totalLength + 1)}
    //     />
    //   ),
    //   amount: (
    //     <VuiInput
    //       inputProps={{ type: "number" }}
    //       defaultValue={shipperDataTableValue[totalLength].amount}
    //       sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
    //         border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    //       })}
    //       placeholder={"Amount"}
    //       // value={commodityValue[0].description}
    //       onChange={(e) => handleOnChangeCommodity("amount", e.target.value, totalLength + 1)}
    //     />
    //   ),
    //   remove: (
    //     <>
    //       <DeleteOutlineIcon
    //         className="quo-delete-icon-style"
    //         fontSize="medium"
    //         onClick={() => handleDeleteCommodity()}
    //       />
    //       {totalLength + 1}
    //     </>
    //   ),
    // });
    // setShipperDataTable([...shipperDataTable]);
  };

  const handleDeleteCommodity = (id) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>. delete id", value);
    console.log(">>>>>>>>>>>>>>> shipper datatable value", shipperDataTableValue);
    console.log(">>>>>>>>>>>>>>> delete index", id);
    // let indexNumber = shipperDataTableValue.findIndex((element) => element.customId == value)
    let newShipperData = [...shipperDataTableValue];
    newShipperData.splice(id, 1);
    console.log(">>>>>>>>>>>>. new shipper data", newShipperData);
    setShipperDataTableValue([...newShipperData]);
  };

  const handleOnChangeCommodity = (name, value, id) => {
    console.log(">>>>>>>>>>>>>>>>>>.handleOnChangeCommodity", name, value, id - 1);
    if (name === "productCode") {
      shipperDataTableValue[id].productCode = value;
    } else if (name === "descriptionOfGoods") {
      shipperDataTableValue[id].descriptionOfGoods = value;
    } else if (name === "unitQty") {
      shipperDataTableValue[id].unitQty = value;
    } else if (name === "unitType") {
      shipperDataTableValue[id].unitType = value;
    } else if (name === "price") {
      shipperDataTableValue[id].price = value;
    } else if (name === "amount") {
      shipperDataTableValue[id].amount = value;
    }
    console.log(">>>>>>>>>>>>>> new commodity", shipperDataTableValue);
    setShipperDataTableValue([...shipperDataTableValue]);
  };

  function handleSubmit() {
    if (
      mmsiKey == "" &&
      imo == "" &&
      name == "" &&
      image == "" &&
      yearBuild == "" &&
      homePort == "" &&
      callSign == "" &&
      shipFlag == "" &&
      deadWeight == "" &&
      capacity == "" &&
      shipType == ""
    ) {
      swal("", "All Fields are required", "error");
      return;
    }

    const userData = {
      mmsiKey: mmsiKey,
      imo: imo,
      name: name,
      image: "",
      yearBuild: yearBuild,
      homePort: homePort,
      callSign: callSign,
      shipFlag: shipFlag,
      deadWeight: deadWeight,
      capacity: capacity,
      shipType: shipType,
      standard20: standard20,
      standard40: standard40,
      highcube40: highcube40,
      refrigerated20: refrigerated20,
      refrigerated40: refrigerated40,
      higcube45: higcube45,
      weightMT: 0,
      volumeM: 0,
      grossWeightMT: 0,
      routeShip: [],
    };
    createShip(userData).then((response) => {
      if (response.success) {
        swal("Great!", " Ship added successfully", "success").then(() => {
          window.location = "#/merchant/inactive-ships";
        });
      } else {
        swal("", response.message, "error");
      }
    });
  }

  const handleGetCompany = () => {
    getCompany().then((response) => {
      let newResponse = [];
      if (response.length > 0) {
        for (let data of response) {
          newResponse.push({
            ...data,
            label: data.company_name,
          });
        }
      }
      setCompany(newResponse);
      console.log(">>>>>>>>>>>> get Company new response", newResponse);
    });
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = data.portName;
          return data;
        });
        console.log(resp, "resp");
        setSearchedPorts(resp);
        console.log(searchedPorts, "searchedPorts");
      });
    }
  };

  const handleUploadLogo = (e) => {
    console.log(">>>>>>>>>>>>>> upload file", e.target.files[0]);
    setLogo(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgsrc([reader.result]);
    };
  };

  const handleDeleteLogo = () => {
    setImgsrc("");
  };

  const handleAddShipper = (type) => {
    if (type === "from") {
      setDialogInformation({
        title: "Add From",
        type: "from",
      });
    } else if (type === "to") {
      setDialogInformation({
        title: "Add to",
        type: "to",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setDialogInformation({
      title: "",
      type: "from",
    });
    setOpen(false);
  };

  const handleSaveDialogData = () => {
    let errorLength = 0;
    let type = dialogInformation.type;

    console.log(
      "conditions--------",
      commonCompany.companyName !== "",
      commonCompany.place !== "",
      commonCompany.address !== "",
      "" + commonCompany.phone !== "",
      "" + commonCompany.bankDetails !== "",
      commonCompany
    );

    {
      errorLength = 1;
    }

    if (errorLength === 1) {
      addCompany({ ...commonCompany })
        .then((response) => {
          if (type == "from") {
            setFrom({ ...commonCompany });
          } else if (type == "to") {
            setTo({ ...commonCompany });
          }
          handleAddCompany();
        })
        .catch((error) => {
          console.log("add company error", error);
        });
    } else {
      alert("Somthing went wrong!");
    }
  };

  const handleAddCompany = () => {
    handleGetCompany();
    handleClose();
    setCommonCompany({
      companyName: "",
      place: "",
      address: "",
      phone: "",
      bankDetails: "",
    });
    swal("", "Company added successfully", "success");
  };

  const handleDialogInformation = (key, value) => {
    console.log(">>>>>>>>>>>>>> handleDialogInformation", key, value);
    setCommonCompany({ ...commonCompany, [key]: value });
  };

  const clearCanvas = () => {
    sigCanvas.current.clear();
  };

  const handleSaveSignature = () => {
    setSignature(sigCanvas.current.toDataURL());
    //setSignature("");
  };

  const handleShowSinature = () => {
    sigCanvas.current.fromDataURL(signature);
  };

  const handleSavePDF = () => {
    ReactPDF.render(
      <PDFDocument
        value={{
          logo: imgSrc,
          fromCompany: from,
          quoteNumber: quoteNumber,
          datee: datee,
          toCompany: to,
          methodOfDispatch: methodOfDispatch,
          typeOfShipment: typeOfShipment,
          portOfLoading: portOfLoading,
          portOfDischarge: portOfDischarge,
          totalAmount: totalAmount,
          additionalInformation: additionalInformation,
          placeOfIssue: placeOfIssue,
          date: date,
          signatoryCompany: signatoryCompany,
          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
          signature: signature,
          bankDetails: bankDetails,
          shipperDataTableValue: shipperDataTableValue,
        }}
      />,
      `${__dirname}/example.pdf`
    );
  };

  const handleShowPreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCheckedDataTableValue = () => {
    let error = false;
    for (let data of shipperDataTableValue) {
      if (
        !error &&
        (data.productCode === "" ||
          data.descriptionOfGoods === "" ||
          data.unitQty === "" ||
          data.unitType === "" ||
          data.price === "" ||
          data.amount === "")
      ) {
        console.log(">>>>>>>>>>>>>>>>if error");
        error = true;
      }
    }
    console.log(">>>>>>>>>>>>> error", error);
    return error;
  };

  const handleSubmitBillOfLading = () => {
    let tableError = handleCheckedDataTableValue();
    if (
      imgSrc !== "" &&
      from.companyName !== "" &&
      quoteNumber !== "" &&
      datee !== "" &&
      to.companyName !== "" &&
      portOfLoading !== "" &&
      portOfDischarge !== "" &&
      methodOfDispatch !== "" &&
      typeOfShipment !== "" &&
      totalAmount !== "" &&
      additionalInformation !== "" &&
      placeOfIssue !== "" &&
      date !== "" &&
      signatoryCompany !== "" &&
      nameOfAuthorizedSignatory !== "" &&
      signature !== "" &&
      shipperDataTableValue !== ""
    ) {
      console.log("all ok");
      let goodsAndPackages = [];
      for (let item of shipperDataTableValue) {
        goodsAndPackages.push({
          productCode: item.productCode,
          descOfGoods: item.descriptionOfGoods,
          unitQty: item.unitQty,
          unitType: item.unitType,
          price: item.price,
          amount: item.amount,
        });
      }

      let formData = new FormData();
      // console.log( this.state.invoice,"invoic");
      let finalData = {
        quoteNumber: quoteNumber,
        datee: date,
        portOfLanding: portOfLoading,
        portOfDischarge: portOfDischarge,
        methodOfDispatch: methodOfDispatch,
        typeOfShipment: typeOfShipment,
        totalAmount: totalAmount,
        additionalInfo: additionalInformation,
        placeOfIssue: placeOfIssue,
        signatoryCompany: signatoryCompany,
        placeOfDate: date,
        signatureUrl: signature,
        merchantId: "CIF",
        shipperCompanyDataId: shipper.id,
        consigneeCompanyDataId: consignee.id,
        carrierNameCompanyDataId: carrierName.id,
        notifyPartyCompanyDataId: notifyParty.id,
        additionalNotifyPartyCompanyDataId: addNotifyParty.id,
        goodsAndPackages: goodsAndPackages,
      };
      console.log(">>>>>>>>>>>>>finale data", finalData);
      formData.append("logo", logo ? logo : null);
      formData.append("data", JSON.stringify(finalData));

      postBillOfLading(formData).then((response) => {
        console.log("response bill of lading", response);
        swal("", "All Fields are required", "success");
      });
    } else {
      swal("", "All Fields are required", "error");
    }
    {
      /* 
    console.log("shipper", shipper);
    console.log("shipper Reference 1", shipperReference);
    console.log("shipper Reference 2", shipperReferenceSecond);
    console.log("Carrier reference", carrierReference);
    console.log("unique consignment reference", uniqueConsignmentReference);
    console.log("consignee", consignee);
    console.log("carrier name", carrierName);
    console.log("notifyParty", notifyParty);
    console.log("addition notify party", addNotifyParty);
    console.log("pre carriage by", preCarriageBy);
    console.log("placeOfReceipt", placeOfReceipt);
    console.log("additional info", additionalInformation);
    console.log("transport", transport);
    console.log("voyage", voyageNo);
    console.log("placeOfDelivery", placeOfDelivery);
    console.log("portofloading", portOfLoading);
    console.log("port of discharge", portOfDischarge);
    console.log("total net weight", totalNetWeight);
    console.log("total gross weight", totalGrossWeight);
    console.log("total volume", totalVolume);
    console.log("additional information", additionalInformationSecond);
    console.log("place of issue", placeOfIssue);
    console.log("date", date);
    console.log("signatory company", signatoryCompany);
    console.log("name of authorizes", nameOfAuthorizedSignatory);
    console.log("signature", signature);
    console.log("shipperDataTableValue", shipperDataTableValue);
    */
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        {!showPreview ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <VuiBox mb="40px">
                  <VuiTypography variant="lg" color="white" fontWeight="bold">
                    Quotation Invoice
                  </VuiTypography>
                </VuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={"quo-download-preview-save"}>
                  <VuiButton
                    variant="contained"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleShowPreview}
                  >
                    Preview
                  </VuiButton>

                  <PDFDownloadLink
                    document={
                      <PDFDocument
                        value={{
                          logo: imgSrc,
                          fromCompany: from,
                          quoteNumber: quoteNumber,
                          datee: datee,
                          toCompany: to,
                          buyerReference: buyerReference,
                          methodOfDispatch: methodOfDispatch,
                          typeOfShipment: typeOfShipment,
                          portOfLoading: portOfLoading,
                          portOfDischarge: portOfDischarge,
                          totalAmount: totalAmount,
                          additionalInformation: additionalInformation,
                          placeOfIssue: placeOfIssue,
                          date: date,
                          signatoryCompany: signatoryCompany,
                          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                          signature: signature,
                          bankDetails: bankDetails,
                          shipperDataTableValue: shipperDataTableValue,
                        }}
                      />
                    }
                    fileName="Document"
                  >
                    {({ loading }) =>
                      loading ? (
                        <VuiButton
                          variant="contained"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Loading.....
                        </VuiButton>
                      ) : (
                        <VuiButton
                          variant="contained"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Download
                        </VuiButton>
                      )
                    }
                  </PDFDownloadLink>
                  <VuiButton
                    variant="contained"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                  >
                    Save as
                  </VuiButton>
                  <VuiButton
                    variant="contained"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleSavePDF}
                  >
                    Save
                  </VuiButton>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={"quo-upload-clear"}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ marginRight: "30px" }}
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadLogo}
                      />
                      {/* <Button variant="contained" component="span">
                  Upload
                </Button> */}
                      <VuiButton
                        variant="contained"
                        component="span"
                        color="info"
                        sx={{ minWidth: "100px" }}
                      >
                        Upload Logo
                      </VuiButton>
                    </label>
                  </Stack>
                  {imgSrc ? (
                    <VuiButton
                      variant="contained"
                      component="span"
                      color="info"
                      sx={{ minWidth: "100px" }}
                      style={{ marginTop: "10px" }}
                      onClick={handleDeleteLogo}
                    >
                      Delete Logo
                    </VuiButton>
                  ) : (
                    ""
                  )}
                </div>
                {imgSrc ? <img src={imgSrc} style={{ width: "100px", height: "100px" }} /> : ""}
              </Grid>
              <Grid item xs={12} lg={6} textAlign={"right"}>
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                  className={"quo-clear"}
                >
                  Clear
                </VuiTypography>
              </Grid>
            </Grid>

            <VuiBox component="form">
              <div container spacing={2} className={"quo-custom-grid-form"}>
                <div item xs={12} lg={6} className="quo-from">
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      From
                    </VuiTypography>
                  </VuiBox>
                  {/*  <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={from.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Select"}
                value={from.companyName}
              />
              */}
                  <VuiSelect
                    placeholder="Select"
                    options={company}
                    value={from}
                    defaultValue={{ value: "", label: "" }}
                    onChange={(e) => {
                      console.log(">>>>>>>>>> place recepite", e);
                      setFrom(e);
                    }}
                  />

                  <div className={"quo-add-from"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                      onClick={() => handleAddShipper("from")}
                    >
                      <AddIcon size={18} />
                      Add
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className="quo-page">
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="h6" fontWeight="bold" color="white">
                      Pages <br />1 of 1
                    </VuiTypography>
                  </VuiBox>
                </div>

                <div item xs={12} lg={6} className={"quo-number"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Quote Number
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    value={quoteNumber}
                    onChange={(e) => setQuoteNumber(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-date1"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={datee}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    value={datee}
                    onChange={(e) => setDatee(e.target.value)}
                  />
                </div>

                {/* <div item xs={12} lg={6} columnSpacing={2}></div> */}

                <div item xs={12} lg={6} className={"quo-port-of-loading"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Port of Loading
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Port of Loading"
                    options={searchedPorts}
                    value={searchedPorts.find((element) => element.label === portOfLoading)}
                    onInputChange={(e) => findPort(e)}
                    onChange={(e) => setPortOfLoading(e.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-port-of-discharge"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Port of Discharge
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Port of Discharge"
                    options={searchedPorts}
                    value={searchedPorts.find((element) => element.label === portOfDischarge)}
                    onInputChange={(e) => findPort(e)}
                    onChange={(e) => setPortOfDischarge(e.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-to"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      To
                    </VuiTypography>
                  </VuiBox>
                  {/*  <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={to.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Select"}
                value={to.companyName}
              />
              */}
                  <VuiSelect
                    placeholder="Select"
                    options={company}
                    value={to}
                    defaultValue={{ value: "", label: "" }}
                    onChange={(e) => {
                      console.log(">>>>>>>>>> place recepite", e);
                      setTo(e);
                    }}
                  />
                  <div className={"quo-add-from"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                      onClick={() => handleAddShipper("to")}
                    >
                      <AddIcon size={18} />
                      Add
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"quo-method-of-dispatch"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Method of Dispatch
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "Truck", label: "Truck" },
                      { value: "Container", label: "Container" },
                      { value: "Wagon", label: "Wagon" },
                    ]}
                    value={methodOfDispatch}
                    defaultValue={{ value: "", label: "" }}
                    onChange={(e) => setMethodOfDispatch(e)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-type-of-shipment"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Type of Shipment
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "FCL", label: "FCL" },
                      { value: "LCL", label: "LCL" },
                      { value: "BULK", label: "BULK" },
                      { value: "FTL", label: "FTL" },
                      { value: "LTL", label: "LTL" },
                      { value: "RAIL", label: "RAIL" },
                      { value: "AIR", label: "AIR" },
                    ]}
                    value={typeOfShipment}
                    defaultValue={{ value: "", label: "" }}
                    onChange={(e) => setTypeOfShipment(e)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-data-table quo-datatable"}>
                  <DataTable
                    showTotalEntries={false}
                    entriesPerPage={false}
                    table={{
                      columns: [
                        {
                          Header: "Product Code",
                          accessor: "productCode",
                          align: "center",
                        },
                        {
                          Header: "Description of Goods",
                          accessor: "descriptionOfGoods",
                          align: "center",
                        },
                        {
                          Header: "Unit Qty",
                          accessor: "unitQty",
                          align: "center",
                        },
                        {
                          Header: "Unit Type",
                          accessor: "unitType",
                          align: "center",
                        },
                        {
                          Header: "Price",
                          accessor: "price",
                          align: "center",
                        },
                        {
                          Header: "Amount",
                          accessor: "amount",
                          align: "center",
                        },
                        { Header: "Remove", accessor: "remove", width: "2%", align: "center" },
                      ],
                      rows: shipperDataTableValue.map((item, index) => {
                        return {
                          productCode: (
                            <VuiInput
                              inputProps={{ type: "string" }}
                              defaultValue={item.productCode}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              // style={{width: "auto", minWidth: "160px"}}
                              placeholder={"Marks and Numbers"}
                              value={item.productCode}
                              onChange={(e) =>
                                handleOnChangeCommodity("productCode", e.target.value, index)
                              }
                            />
                          ),

                          descriptionOfGoods: (
                            <VuiInput
                              inputProps={{ type: "string" }}
                              defaultValue={item.descriptionOfGoods}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              placeholder={"Description Of Goods"}
                              value={item.descriptionOfGoods}
                              onChange={(e) =>
                                handleOnChangeCommodity("descriptionOfGoods", e.target.value, index)
                              }
                            />
                          ),
                          unitQty: (
                            <VuiInput
                              inputProps={{ type: "number" }}
                              defaultValue={item.unitQty}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              placeholder={"unitQty"}
                              value={item.unitQty}
                              onChange={(e) =>
                                handleOnChangeCommodity("unitQty", e.target.value, index)
                              }
                            />
                          ),
                          unitType: (
                            <VuiInput
                              inputProps={{ type: "number" }}
                              defaultValue={item.unitType}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              placeholder={"unitType"}
                              value={item.unitType}
                              onChange={(e) =>
                                handleOnChangeCommodity("unitType", e.target.value, index)
                              }
                            />
                          ),
                          price: (
                            <VuiInput
                              inputProps={{ type: "string" }}
                              defaultValue={item.price}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              placeholder={"Price"}
                              value={item.price}
                              onChange={(e) =>
                                handleOnChangeCommodity("price", e.target.value, index)
                              }
                            />
                          ),
                          amount: (
                            <VuiInput
                              inputProps={{ type: "string" }}
                              defaultValue={item.amount}
                              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                              })}
                              placeholder={"Amount"}
                              value={item.amount}
                              onChange={(e) =>
                                handleOnChangeCommodity("amount", e.target.value, index)
                              }
                            />
                          ),
                          remove: (
                            <>
                              {shipperDataTableValue && shipperDataTableValue.length > 1 ? (
                                <DeleteOutlineIcon
                                  className="quo-delete-icon-style"
                                  fontSize="medium"
                                  onClick={() => handleDeleteCommodity(index)}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ),
                        };
                      }),
                    }}
                  />

                  <div className={"quo-add-data-table-row"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                      onClick={hanldeAddDataTableRow}
                    >
                      <AddIcon size={18} />
                      Add
                    </VuiTypography>
                  </div>
                </div>

                <div className="quo-unitType-price-amount">
                  <div item xs={12} lg={6}>
                    <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="white"
                      >
                        Total Amount
                      </VuiTypography>
                    </VuiBox>
                    <VuiInput
                      inputProps={{ type: "number" }}
                      defaultValue={""}
                      sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                        border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                      })}
                      value={totalAmount}
                      onChange={(e) => setTotalAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div item xs={12} lg={6} className={"quo-additional-inform"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Additional information
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    multiline
                    rows={8}
                    value={additionalInformation}
                    onChange={(e) => setAdditionalInformation(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-place-of-issue"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Place of Issue
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={searchedPorts}
                    value={searchedPorts.find((element) => element.label === placeOfIssue)}
                    onInputChange={(e) => findPort(e)}
                    onChange={(e) => setPlaceOfIssue(e.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-date"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={date}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    placeholder={"Date"}
                    value={date}
                    disabled={false}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-signatory-company"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Signatory Company
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    value={signatoryCompany}
                    onChange={(e) => setSignatoryCompany(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-authorized-Signatory"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Name of Authorized Signatory
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    placeholder={"Name of Authorized Signatory"}
                    value={nameOfAuthorizedSignatory}
                    onChange={(e) => setNameOfAuthorizedSignatory(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-bank-details"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Bank Details
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    multiline
                    rows={8}
                    value={bankDetails}
                    onChange={(e) => setBankDetails(e.target.value)}
                  />
                </div>

                <div item xs={12} lg={6} className={"quo-signature"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                    >
                      Signature
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox style={{ display: "flex" }}>
                    <SignaturePad
                      ref={sigCanvas}
                      penColor="#607d8b"
                      onEnd={handleSaveSignature}
                      canvasProps={{
                        className: "quo-signatureCanvas",
                      }}
                    />
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="white"
                      onClick={clearCanvas}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    >
                      Clear
                    </VuiTypography>
                    {/*    <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                  onClick={handleShowSinature}
                >
                  Show
                </VuiTypography>
                */}
                  </VuiBox>
                </div>
              </div>
            </VuiBox>
          </>
        ) : (
          ""
        )}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 510 } }}
          maxWidth="xs"
          open={open}
        >
          <DialogTitle>{dialogInformation.title}</DialogTitle>
          <DialogContent dividers>
            <div item xs={12} lg={6} className="quo-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="black">
                  Company name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Company Name"}
                // value={commodityValue[0].description}
                onChange={(e) => handleDialogInformation("companyName", e.target.value)}
              />
            </div>
            <div item xs={12} lg={6} className="quo-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="black">
                  Place
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Place"
                options={searchedPorts}
                value={searchedPorts.find((element) => element.label === commonCompany.place)}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => handleDialogInformation("place", e.value)}
              />
            </div>
            <div item xs={12} lg={6} className="quo-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="black">
                  Address
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.address}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Address"}
                // value={commodityValue[0].description}
                onChange={(e) => handleDialogInformation("address", e.target.value)}
              />
            </div>
            <div item xs={12} lg={6} className="quo-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="black">
                  Phone
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={commonCompany.phone}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Phone"}
                // value={commodityValue[0].description}
                onChange={(e) => handleDialogInformation("phone", e.target.value)}
              />
            </div>
            <div item xs={12} lg={6} className="quo-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="black">
                  Bank Details
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                defaultValue={commonCompany.bankDetails}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Bank Details"}
                // value={commodityValue[0].description}
                onChange={(e) => handleDialogInformation("bankDetails", e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveDialogData}>Save</Button>
          </DialogActions>
        </Dialog>

        {showPreview ? (
          <div>
            <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
              <VuiButton
                variant="contained"
                component="span"
                color="info"
                sx={{ minWidth: "100px" }}
                onClick={handleClosePreview}
              >
                Close
              </VuiButton>
            </div>
            <div>
              <PDFViewer style={{ height: "500px", width: "100%" }}>
                <PDFDocument
                  value={{
                    logo: imgSrc,
                    fromCompany: from,
                    quoteNumber: quoteNumber,
                    datee: datee,
                    toCompany: to,
                    methodOfDispatch: methodOfDispatch,
                    typeOfShipment: typeOfShipment,
                    portOfLoading: portOfLoading,
                    portOfDischarge: portOfDischarge,
                    totalAmount: totalAmount,
                    additionalInformation: additionalInformation,
                    placeOfIssue: placeOfIssue,
                    date: date,
                    signatoryCompany: signatoryCompany,
                    nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                    signature: signature,
                    bankDetails: bankDetails,
                    shipperDataTableValue: shipperDataTableValue,
                  }}
                />
              </PDFViewer>
            </div>
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <VuiButton
            variant="contained"
            component="span"
            color="info"
            sx={{ minWidth: "100px" }}
            // onClick={handleSubmitBillOfLading}
          >
            Submit
          </VuiButton>
        </div>
        {console.log(">>>>>>>>>>>>>> table data", shipperDataTable)}
      </Card>
    </DashboardLayout>
  );
}

export default QuotationInvoice;
