

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";



function SupportTicketDetails({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, company, email, password, repeatPassword, text } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    company: companyV,
    email: emailV,
    text: textV,
    repeatPassword: repeatPasswordV,
  } = values;

  return (
    <VuiBox>
      <VuiBox lineHeight={0} display="flex" flexDirection="column">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Support Ticket
        </VuiTypography>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Mandatory informations
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={firstName.label}
              name={firstName.name}
              type={firstName.type}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              type={lastName.type}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={company.label}
              name={company.name}
              type={company.type}
              value={companyV}
              placeholder={company.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={email.label}
              name={email.name}
              type={email.type}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <VuiInput placeholder="Type here..." multiline rows={5} />
          </Grid>
          <Grid item xs={12} sm={6}>
          <VuiButton variant="gradient" color="info" size="large" >Button</VuiButton>
        </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

// typechecking props for SupportTicketDetails
SupportTicketDetails.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SupportTicketDetails;
