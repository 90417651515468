// Vision UI Dashboard PRO React Base Styles
import bgAdmin from "assets/images/body-background.png";
import bgLight from "assets/images/body-background-light.svg";
import colors from "assets/themeLight/base/colors";

const { info, dark } = colors;

export default {
  html: {
    scrollBehavior: "smooth",
  },

  body: {
    background: `url(${bgLight})`,
    backgroundColor: `#f2f2f2 !important`,
    backgroundSize: "cover",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
};
