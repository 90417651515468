import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { LclContainerQuoatation } from "services/containerService";

function LCLContainerQuoatation() {
  let history = useHistory();
  useEffect(async()=>{
    await handleGetLclContainerQuoatation()
  },[])

  const [lclContainerQuoatation, setLclContainerQuoatation] = useState("");

  const handleGetLclContainerQuoatation = () => {
    LclContainerQuoatation().then(Response => {
        console.log(">>>>>>>>>>>>>>>>>>>> LCL container quoatation", Response)
        let newResponse = [];
        for(let item of Response)
        {
          newResponse.push({...item,departureTime: getTime(item.departureTime),arrivalTime: getTime(item.arrivalTime)})
        }
        setLclContainerQuoatation(newResponse);
    })
  }

  const dates = (time)=>{
    var d = new Date(time);
    var time =d.getUTCMonth()+1+" / "+d.getDate()+" / "+d.getUTCFullYear() 
    return time
  }

  const datesWithTime = (time)=>{
    var d = new Date(time);
    var time = d.getDate()+" / "+ (d.getUTCMonth()+1)+" / "+d.getUTCFullYear()+ " "+ d.getHours() +":"+ d.getMinutes()
    return time
  }

  const getTime = (time)=>{
    var d = new Date(time);
    var time = d.getHours() +":"+ d.getMinutes()
    return time
  }

  const showLCLContainerDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/merchant/lcl-container-quotation-details",
      details: data,
    });
  };

  const lclContainerQuoatationData = {
    columns: [
      { Header: "Container Key", accessor: "containerKey", },
      { Header: "Departure Time", accessor: "departureTime"},
      { Header: "Arrival Time", accessor: "arrivalTime"},
      { Header: "Current Port", accessor: "currentPort"},
      { Header: "Destination Port", accessor: "destinationPort"},
      { Header: "Action", Cell: (cell) => (
        <VuiButton variant="gradient" color="info" size="medium" onClick={() => showLCLContainerDetail(cell.row.original)}>More Details</VuiButton>
      ) },
    ],

    rows: lclContainerQuoatation ? lclContainerQuoatation: [],
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
            List of Book LCL Container
            </VuiTypography>
            {/* <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all Container which are ready to shipped.
            </VuiTypography> */}
          </VuiBox>
          <DataTable table={lclContainerQuoatationData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LCLContainerQuoatation;
