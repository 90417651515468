import { useState, useEffect } from "react";

import { Link, useParams, Redirect } from "react-router-dom";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
import VuiTagInput from "components/VuiTagInput";
import VuiButton from "components/VuiButton";
import VuiDatePicker from "components/VuiDatePicker";
import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Service
import { createContainer, activateContainerLcl } from "services/containerService";
import { getPorts, getActiveShips } from "services/shipService";
import swal from "sweetalert";

function ActivateLclContainer() {
  const [errorMsg, setErrorMsg] = useState({
    category: { errorMsg: "" },
    currentPort: { errorMsg: "" },
    departureTime: { errorMsg: "" },
    arrivalTime: { errorMsg: "" },
    readyToLoad: { errorMsg: "" },
    destinationPort: { errorMsg: "" },
    lclArea: { errorMsg: "" },
    lclPrice: { errorMsg: "" },
    lclEmptyArea: { errorMsg: "" },
    shipStopPorts: { errorMsg: "" },
    shipKey: { errorMsg: "" },
  });
  const [category, setcategory] = useState("");
  const [originPort, setoriginPort] = useState("");
  const [DepartureTime, setDepartureTime] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [ArrivalTime, setArrivalTime] = useState("");
  const [readyToLoad, setReadyToLoad] = useState("");
  const [lclArea, setlclArea] = useState("");
  const [lclPrice, setlclPrice] = useState("");
  const [lclEmptyArea, setlclEmptyArea] = useState("");
  const [ports, setContainerStopPorts] = useState([]);
  const [containerLocation, setcontainerLocation] = useState("");
  const [currentPort, setcurrentPort] = useState([]);
  const [shipMmsiKey, setshipMmsiKey] = useState("");
  const [searchActiveShip, setsearchActiveShip] = useState("");
  const [searchedPorts, setSearchedPorts] = useState([]);

  const { containerKey } = useParams();

  const handleActivateLclContainer = () => {
    let errorLengthCategory = customValidations("category", category);
    let errorLengthReadyToLoad = customValidations("readyToLoad", readyToLoad);
    let errorLengthDepartureTime = customValidations("departureTime", DepartureTime);
    let errorLengthArrivalTime = customValidations("arrivalTime", ArrivalTime);
    let errorLengthDestinationPort = customValidations("destinationPort", destinationPort);
    let errorLengthLclPrice = customValidations("lclPrice", lclPrice);
    let errorLengthLclArea = customValidations("lclArea", lclArea);
    let errorLengthShipLclEmptyArea = customValidations("lclEmptyArea", lclEmptyArea);
    let errorLengthShipKey = customValidations("shipKey", shipMmsiKey);
    if (
      errorLengthCategory !== 1 &&
      errorLengthReadyToLoad !== 1 &&
      errorLengthDepartureTime !== 1 &&
      errorLengthArrivalTime !== 1 &&
      errorLengthDestinationPort !== 1 &&
      errorLengthLclPrice !== 1 &&
      errorLengthLclArea !== 1 &&
      errorLengthShipLclEmptyArea !== 1 &&
      errorLengthShipKey
    ) {
      console.log("all ok ");
    } else {
      console.log("input errrrrrorrrr");

      return;
    }

    let newReadyToload = readyToLoad.split(" ");
    newReadyToload = newReadyToload[0];
    const activateFClData = {
      category: category,
      originPort: setcurrentPort,
      departureTime: "" + DepartureTime.replace(/ /g, "T"),
      destinationPort: destinationPort[0],
      arrivalTime: "" + ArrivalTime.replace(/ /g, "T"),
      lclArea: lclArea,
      lclPrice: lclPrice,
      lclEmptyArea: lclEmptyArea,
      ports: ports,
      containerLocation: containerLocation,
      readyToLoad: newReadyToload,
      currentPort: currentPort[0],
      status: "Ready to Load",
      shipMmsiKey: shipMmsiKey.value,
      containerKey: containerKey,
      containerStatus: "active",
      bookingType: "LCL",
    };

    console.log("containerAcitvateInformation", activateFClData);

    const addContainer = activateContainerLcl(activateFClData).then((response) => {
      if (response.success == true) {
        swal("Great!", "Container Activated successfully", "success").then(() => {
          window.location = "#/merchant/active-conatiners";
        });
      } else {
        swal("", response.message, "error");
      }
    });
  };

  const getMultiports = (e) => {
    const portName = [currentPort[0]];
    const containerLocation = [currentPort[1]];
    const multiPort = e.map((data) => {
      portName.push(data.portName);
      containerLocation.push([data.xcoord, data.ycoord]);
    });
    portName.push(destinationPort[0]);
    containerLocation.push(destinationPort[1]);
    setContainerStopPorts(portName);
    setcontainerLocation(containerLocation);
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = [data.portName, [data.xcoord, data.ycoord]];
          return data;
        });
        setSearchedPorts(resp);
      });
    }
  };

  useEffect(async () => {
    await fetchActiveDetails();
  }, []);

  const fetchActiveDetails = () => {
    getActiveShips().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>> Data ");
      const resp = Response.map((data) => {
        data.label = `${data.name} (${data.mmsiKey})`;
        data.value = data.mmsiKey;
        return data;
      });
      setsearchActiveShip(resp);
    });
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.activateShipValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    customValidations(name, value);

    if (name === "category") {
      setcategory(value);
      // } else if (name === "currentPort") {
      //   setCurrentPort(value);
    } else if (name === "departureTime") {
      setDepartureTime(value);
    } else if (name === "arrivalTime") {
      setArrivalTime(value);
    } else if (name === "readyToLoad") {
      setReadyToLoad(value);
    } else if (name === "destinationPort") {
      setDestinationPort(value);
    } else if (name === "lclPrice") {
      setlclPrice(value);
    } else if (name === "lclArea") {
      setlclArea(value);
    } else if (name === "lclEmptyArea") {
      setlclEmptyArea(value);
    } else if (name === "shipKey") {
      setshipMmsiKey(value);
    } else {
      console.log("ERRRORRRRRRRRR");
    }
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="Activate LCL Container" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Activate LCL Container
          </VuiTypography>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* <FormField label="Category" placeholder="Add Category"  
                    value={category}
                    onChange={e => setcategory(e.target.value)} /> */}

              <VuiTypography variant="button" color="white" fontWeight="regular">
                Category
              </VuiTypography>
              <VuiInput
                name="category"
                placeholder="Enter Category"
                value={category}
                error={errorMsg.category.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.category.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Current Port
              </VuiTypography>
              <VuiSelect
                placeholder="Select Current Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => setcurrentPort(e.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Ready to Load
              </VuiTypography>
              <VuiDatePicker
                label="Ready to Load"
                input={{ placeholder: "Select a date" }}
                value={readyToLoad}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{ enableTime: true, minDate: new Date() }}
                error={errorMsg.readyToLoad.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("readyToLoad", e[0].toMysqlFormat())}
                // onChange={(e) => setReadyToLoad(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.readyToLoad.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Departure Time
              </VuiTypography>
              <VuiDatePicker
                label="Enter Departure Time"
                input={{ placeholder: "Select a date" }}
                value={DepartureTime}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{ enableTime: true, minDate: new Date(readyToLoad) }}
                error={errorMsg.departureTime.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("departureTime", e[0].toMysqlFormat())}
                // onChange={(e) => setDepartureTime(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.departureTime.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Arrival Time for Destination Port
              </VuiTypography>
              <VuiDatePicker
                label="Enter Arrival Time"
                input={{ placeholder: "Select a date" }}
                value={ArrivalTime}
                // options={{ enableTime: true, dateFormat: "Y-m-d H:i" }}
                options={{
                  enableTime: true,
                  dateFormat: "Y-m-d H:i",
                  minDate: new Date(DepartureTime),
                }}
                error={errorMsg.arrivalTime.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("arrivalTime", e[0].toMysqlFormat())}
                // onChange={(e) => setArrivalTime(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.arrivalTime.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Destination Port
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                size="medium"
                fontSize="bold"
                placeholder="Select Destination Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                // onChange={(e) => setDestinationPort(e.value)}
                error={errorMsg.destinationPort.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("destinationPort", e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.destinationPort.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <FormField
                label="LCL AREA"
                placeholder="Enter LCL AREA"
                inputProps={{ type: "number" }}
                value={lclArea}
                onChange={(e) => setlclArea(e.target.value)}
              /> */}

              <VuiTypography variant="button" color="white" fontWeight="regular">
                Lcl Area
              </VuiTypography>
              <VuiInput
                name="lclArea"
                placeholder="Enter Lcl Area"
                value={lclArea}
                error={errorMsg.lclArea.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.lclArea.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <FormField
                label="LCL Price"
                placeholder="Enter LCL Price"
                inputProps={{ type: "number" }}
                value={lclPrice}
                onChange={(e) => setlclPrice(e.target.value)}
              /> */}
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Lcl Price
              </VuiTypography>
              <VuiInput
                name="lclPrice"
                placeholder="Enter Lcl Price"
                value={lclPrice}
                error={errorMsg.lclPrice.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.lclPrice.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <FormField
                label="LCL Empty Area"
                placeholder="Enter Empty Area"
                inputProps={{ type: "number" }}
                value={lclEmptyArea}
                onChange={(e) => setlclEmptyArea(e.target.value)}
              /> */}
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Lcl Empty Area
              </VuiTypography>
              <VuiInput
                name="lclEmptyArea"
                placeholder="Enter Lcl Empty Area"
                value={lclEmptyArea}
                error={errorMsg.lclEmptyArea.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.lclEmptyArea.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Choose Ship Stop ports
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Destination Port"
                isMulti
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                onChange={(e) => getMultiports(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Select Ship Key
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Ship Key"
                options={searchActiveShip}
                value={searchActiveShip.value}
                error={errorMsg.shipKey.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("shipKey", e.value)}
                // onChange={(e) => setshipMmsiKey(e)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.shipKey.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} md={6} mt={"1rem"}>
              <VuiButton
                variant="gradient"
                color="info"
                size="large"
                fontSize="16px"
                onClick={handleActivateLclContainer}
              >
                Activate LCL Container
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  );
}

export default ActivateLclContainer;
