import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base sxs
import typography from "assets/theme/base/typography";
import FormField from "layouts/pages/account/components/FormField";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
// Data
import salesTableData from "layouts/pages/dashboards/data/salesTableData";
import ActiveUsers from "layouts/pages/dashboards/components/ActiveUsers";
import SalesOverview from "layouts/pages/dashboards/components/SalesOverview";
import colors from "assets/theme/base/colors";
import colorsNew from "assets/themeLight/base/colors";
import { useVisionUIController } from "context";

// icons
import {
  FaShoppingCart,
  FaShip,
  FaShippingFast,
  FaReceipt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { IoWallet, IoDocumentText } from "react-icons/io5";
import { GoogleMap, withScriptjs, withGoogleMap, TrafficLayer } from "react-google-maps";

// Charts Data
import { getActiveShips } from "services/shipService";
import { getShipQuoatation } from "services/quoatationService";
import { getActiveContainer } from "services/containerService";
import { FclContainerQuoatation } from "services/containerService";
import { LclContainerQuoatation } from "services/containerService";
import { useEffect, useState } from "react";
import PieChart from "examples/Charts/PieChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import "./data/dashboard.css";

let Map = () => {
  return (
    <GoogleMap defaultZoom={10} defaultCenter={{ lat: 45.421532, lng: -75.697189 }}>
      {" "}
      <TrafficLayer autoUpdate />
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

function Default() {
  const { values } = breakpoints;

  const [activeShipCount, setActiveShipCount] = useState(null);
  const [shipQuoatationCount, setShipQuoatationCount] = useState(null);
  const [activeContainerCount, setActiveContainerCount] = useState(null);
  const [fclContainerCount, setFclContainerCount] = useState(null);
  const [lclContainerCount, setLclContainerCount] = useState(null);
  const [controller] = useVisionUIController();
  const { themeMode } = controller;
  console.log(themeMode);
  const palette = themeMode === false ? colors : colorsNew;

  const { dark, white, text, inputColors, gradients, transparent, grey, info } = palette;
  console.log(white.main);

  useEffect(() => {
    handleActiveShipCount();
    handleShipQuoatationCount();
    handleActiveContainerCount();
    handleFclContainerCount();
    handleLclContainerCount();
  }, []);

  const handleActiveShipCount = () => {
    getActiveShips().then((response) => {
      console.log(">>>>>>>>>>>>>>>. active ship count", response);
      if (response.length > 0) {
        setActiveShipCount(response.length);
      } else {
        setActiveShipCount(0);
      }
    });
  };

  const handleShipQuoatationCount = () => {
    getShipQuoatation().then((response) => {
      console.log(">>>>>>>>>>>>>>>. ship quoatation count", response);
      if (response.length > 0) {
        setShipQuoatationCount(response.length);
      } else {
        setShipQuoatationCount(0);
      }
    });
  };

  const handleActiveContainerCount = () => {
    getActiveContainer().then((response) => {
      console.log(">>>>>>>>>>>>>>>. active container count", response);
      if (response.length > 0) {
        setActiveContainerCount(response.length);
      } else {
        setActiveContainerCount(0);
      }
    });
  };

  const handleFclContainerCount = () => {
    FclContainerQuoatation().then((response) => {
      console.log(">>>>>>>>>>>>>>>.lcl container count", response);
      if (response.length > 0) {
        setFclContainerCount(response.length);
      } else {
        setFclContainerCount(0);
      }
    });
  };

  const handleLclContainerCount = () => {
    LclContainerQuoatation().then((response) => {
      console.log(">>>>>>>>>>>>>>>. fcl container count", response);
      if (response.length > 0) {
        setLclContainerCount(response.length);
      } else {
        setLclContainerCount(0);
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <VuiBox mb={3} VuiTypography={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Business Stacks
              </VuiTypography>
            </VuiBox>
            <Grid container>
              <Grid item xs={12}>
                <Globe
                  display={{ xs: "none", md: "block" }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{ xs: -12, lg: 1 }}
                  mr={{ xs: 0, md: -10, lg: 10 }}
                  canvassx={{ marginTop: "3rem" }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <VuiBox mb={3}>
                  {/* <MiniStatisticsCard
                      title={{ text: "TOTAL ACTIVE SHIP", fontWeight: "bold" }}
                      count={`${activeShipCount !== null ? activeShipCount : 0}`}
                      // percentage={{ color: "success", text: "+55%" }}
                      icon={<FaShip color="white" />}
                    /> */}
                  <Card>
                    <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <VuiBox
                        sx={{
                          backgroundColor: "#0075FF",
                          padding: "5px 9px",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <FaShip color="white" />
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          TOTAL ACTIVE SHIP
                        </VuiTypography>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          {activeShipCount !== null ? activeShipCount : 0}
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <VuiBox>
                  {/* <MiniStatisticsCard
                    title={{ text: "TOTAL BOOK SHIP", fontWeight: "bold" }}
                    count={`${shipQuoatationCount !== null ? shipQuoatationCount : 0}`}
                    // percentage={{ color: "success", text: "+3%" }}
                    icon={<FaShip color="white" />}
                  /> */}
                  <Card>
                    <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <VuiBox
                        sx={{
                          backgroundColor: "#0075FF",
                          padding: "5px 9px",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <FaShip color="white" />
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          TOTAL BOOK SHIP
                        </VuiTypography>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          {shipQuoatationCount !== null ? shipQuoatationCount : 0}
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={3} className="mb-0">
                <VuiBox mb={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "TOTAL ACTIVE CONTAINER", fontWeight: "bold" }}
                    count={`${ activeContainerCount !== null ? activeContainerCount : 0}`}
                    // percentage={{ color: "error", text: "-2%" }}
                    icon={<FaShippingFast color="white" />}
                  /> */}
                  <Card>
                    <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <VuiBox
                        sx={{
                          backgroundColor: "#0075FF",
                          padding: "5px 9px",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <FaShippingFast color="white" />
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          TOTAL ACTIVE CONTAINER
                        </VuiTypography>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          {activeContainerCount !== null ? activeContainerCount : 0}
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={3} className="mb-0">
                <VuiBox mb={3}>
                  {/* <MiniStatisticsCard
                    title={{ text: "TOTAL BOOKED CONTAINER", fontWeight: "bold" }}
                    count={`${fclContainerCount !== null && lclContainerCount !== null ? fclContainerCount + lclContainerCount: 0}`}
                    // percentage={{ color: "success", text: "+5%" }}
                    icon={<FaShippingFast color="white" />}
                  /> */}
                  <Card>
                    <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <VuiBox
                        sx={{
                          backgroundColor: "#0075FF",
                          padding: "5px 9px",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <FaShippingFast color="white" />
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          TOTAL BOOKED CONTAINER
                        </VuiTypography>
                        <VuiTypography color="white" fontSize="0.8rem" fontWeight="bold">
                          {fclContainerCount !== null && lclContainerCount !== null
                            ? fclContainerCount + lclContainerCount
                            : 0}
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={10} lg={8.5} xl={7}>
            <Grid item xs={12} lg={10}>
              <Card>
                <SalesTable title="Sales by Country" rows={salesTableData} />
              </Card>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} xl={5}>
              <Card className="custom-chart-classes" sx={{ width: "100%", height: "420px" }}>
                <DefaultDoughnutChart
                  title=""
                  data={[45, 25, 30]}
                  options={{
                    series: [45, 25, 30],
                    labels: ["On Time", "In Progress", "Delayed"],
                    colors: ["#2d8cfc", "#2370cc", "#0f4a91", "#073a78", "#012654"],
                    chart: {
                      width: "100%",
                    },
                    stroke: {
                      show: false,
                    },
                    states: {
                      hover: {
                        filter: {
                          type: "none",
                        },
                      },
                    },
                    legend: {
                      show: true,
                      // labels: {
                      //   colors: `red !important`,
                      // },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    hover: { mode: null },
                    plotOptions: {
                      donut: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: false,
                          },
                        },
                      },
                    },
                    fill: {
                      colors: ["#2d8cfc", "#2370cc", "#0f4a91", "#073a78", "#012654"],
                    },
                    tooltip: {
                      enabled: true,
                      theme: "dark",
                    },
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} lg={7} xl={7}>
              <Card sx={{ padding: "10px", marginBottom: "10px" }}>
                <VuiBox sx={{ width: "100%", marginTop: "10px" }}>
                  <FormField
                    label="Search"
                    placeholder="Search here...."
                    inputProps={{ type: "string" }}
                    value={""}
                    // onChange={e => setheight(e.target.value)}
                  />
                </VuiBox>
              </Card>
              <Card sx={{ padding: "20px" }}>
                <VuiBox sx={{ width: "100%", height: "265px", marginTop: "10px" }}>
                  <WrappedMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI`}
                    loadingElement={<VuiBox sx={{ height: `100%` }} />}
                    containerElement={<VuiBox sx={{ height: `100%` }} />}
                    mapElement={<VuiBox sx={{ height: `100%` }} />}
                  />
                </VuiBox>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt="2px">
            <Grid item xs={12} lg={5} xl={5}>
              <Card className="custom-chart-classes">
                {activeShipCount !== null &&
                shipQuoatationCount !== null &&
                activeContainerCount !== null &&
                fclContainerCount !== null &&
                lclContainerCount !== null ? (
                  <PieChart
                    title=""
                    data={[
                      activeShipCount,
                      shipQuoatationCount,
                      activeContainerCount,
                      fclContainerCount,
                      lclContainerCount,
                    ]}
                    options={{
                      labels: [
                        "Active Ship",
                        "Booked Ship",
                        "Active Container",
                        "Booked FCL",
                        "Booked LCL",
                      ],
                      colors: ["#1a237e", "#303f9f", "#3f51b5", "#7986cb", "#c5cae9"],
                      chart: {
                        width: "100%",
                        stroke: {
                          show: false,
                        },
                      },
                      states: {
                        hover: {
                          filter: {
                            type: "none",
                          },
                        },
                      },
                      legend: {
                        show: true,
                      },
                      stroke: {
                        show: false,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      hover: { mode: null },
                      plotOptions: {
                        donut: {
                          expandOnClick: false,
                          donut: {
                            labels: {
                              show: false,
                            },
                          },
                        },
                      },
                      fill: {
                        colors: ["#1a237e", "#303f9f", "#3f51b5", "#7986cb", "#c5cae9"],
                      },
                      tooltip: {
                        enabled: true,
                        theme: "dark",
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </Card>
            </Grid>
            <Grid item xs={12} lg={7} xl={7}>
              <Card>
                {activeShipCount !== null &&
                shipQuoatationCount !== null &&
                activeContainerCount !== null &&
                fclContainerCount !== null &&
                lclContainerCount !== null ? (
                  <VerticalBarChart
                    title=""
                    data={[
                      {
                        name: "",
                        data: [
                          activeShipCount,
                          shipQuoatationCount,
                          activeContainerCount,
                          fclContainerCount,
                          lclContainerCount,
                        ],
                      },
                    ]}
                    options={{
                      chart: {
                        toolbar: {
                          show: false,
                        },
                      },
                      tooltip: {
                        sx: {
                          fontSize: "12px",
                          fontFamily: undefined,
                        },
                        onDatasetHover: {
                          sx: {
                            fontSize: "12px",
                            fontFamily: undefined,
                          },
                        },
                        theme: "dark",
                      },
                      xaxis: {
                        categories: [
                          "Active Ship",
                          "Booked Ship",
                          "Active Container",
                          "Booked FCL",
                          "Booked LCL",
                        ],
                        show: true,
                        labels: {
                          show: true,
                          sx: {
                            colors: "#A0AEC0",
                            fontSize: "10px",
                          },
                        },
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                      },
                      yaxis: {
                        show: true,
                        color: "black",
                        labels: {
                          show: true,
                          sx: {
                            colors: "#c5cae9",
                            fontSize: "10px",
                          },
                        },
                      },
                      grid: {
                        strokeDashArray: 5,
                        borderColor: "#56577A",
                        yaxis: {
                          lines: {
                            show: true,
                          },
                        },
                        xaxis: {
                          lines: {
                            show: false,
                          },
                        },
                      },
                      fill: {
                        type: "solid",
                        colors: "#c5cae9",
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      plotOptions: {
                        bar: {
                          borderRadius: 8,
                          columnWidth: "40px",
                        },
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
