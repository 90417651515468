import React from "react";
import { useState, useRef, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Button from "@mui/material/Button";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import jwt_decode from "jwt-decode";
import Avatar from "./avatar.svg";
import validator from "validator";
import { changePassword } from "services/profileService";
import Validations from "../../shared/validations";
import ValidationService from "../../shared/validationService";
import "./profile.css";

const Profile = () => {
  const [errorMsg, setErrorMsg] = useState({
    password: { errorMsg: "" },
    confirmPassword: { errorMsg: "" },
  });
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [newMessage, setNewMessage] = useState(true);
  const [newShipping, setNewShipping] = useState(true);
  const [prevdata, setPrevData] = useState({
    type: "",
    value: "",
  });
  const [companyName, setCompanyName] = useState({
    value: "",
    isEdit: false,
  });
  const [accountType, setAccoutType] = useState({
    value: "",
    isEdit: false,
  });
  const [businessLocation, setBusinessLocation] = useState({
    value: "",
    isEdit: false,
  });
  const [city, setCity] = useState({
    value: "",
    isEdit: false,
  });
  const [streetAddress, setStreetAddress] = useState({
    value: "",
    isEdit: false,
  });
  const [postalCode, setPostCode] = useState({
    value: "",
    isEdit: false,
  });
  const [webSite, setWebSite] = useState({
    value: "",
    isEdit: false,
  });
  const [about, setAbout] = useState({
    value: "",
    isEdit: false,
  });
  const [companyId, setCompanyId] = useState({
    value: "",
    isEdit: false,
  });
  const [firstName, setFirstName] = useState({
    value: "",
    isEdit: false,
  });
  const [lastName, setLastName] = useState({
    value: "",
    isEdit: false,
  });
  const [country, setCountry] = useState({
    value: "",
    isEdit: false,
  });
  const [department, setDepartment] = useState({
    value: "",
    isEdit: false,
  });
  const [jobTitle, setJobTitle] = useState({
    value: "",
    isEdit: false,
  });
  const [languageSpoken, setLanguageSpoken] = useState({
    value: "",
    isEdit: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    isEdit: false,
  });
  const [currency, setCurrency] = useState({
    value: "",
    isEdit: false,
  });
  const [profileId, setProfileId] = useState({
    value: "",
    isEdit: false,
  });

  const handleEditData = (type) => {
    if (type === "companyName") {
      setCompanyName({ ...companyName, isEdit: true });
      setPrevData({ type, value: companyName.value });
    } else if (type === "accountType") {
      setAccoutType({ ...accountType, isEdit: true });
      setPrevData({ type, value: accountType.value });
    } else if (type === "businessLocation") {
      setBusinessLocation({ ...businessLocation, isEdit: true });
      setPrevData({ type, value: businessLocation.value });
    } else if (type === "city") {
      setCity({ ...city, isEdit: true });
      setPrevData({ type, value: city.value });
    } else if (type === "streetAddress") {
      setStreetAddress({ ...streetAddress, isEdit: true });
      setPrevData({ type, value: streetAddress.value });
    } else if (type === "postalCode") {
      setPostCode({ ...postalCode, isEdit: true });
      setPrevData({ type, value: postalCode.value });
    } else if (type === "webSite") {
      setWebSite({ ...webSite, isEdit: true });
      setPrevData({ type, value: webSite.value });
    } else if (type === "about") {
      setAbout({ ...about, isEdit: true });
      setPrevData({ type, value: about.value });
    } else if (type === "companyId") {
      setCompanyId({ ...companyId, isEdit: true });
      setPrevData({ type, value: companyId.value });
    } else if (type === "firstName") {
      setFirstName({ ...firstName, isEdit: true });
      setPrevData({ type, value: firstName.value });
    } else if (type === "lastName") {
      setLastName({ ...lastName, isEdit: true });
      setPrevData({ type, value: lastName.value });
    } else if (type === "country") {
      setCountry({ ...country, isEdit: true });
      setPrevData({ type, value: country.value });
    } else if (type === "department") {
      setDepartment({ ...department, isEdit: true });
      setPrevData({ type, value: department.value });
    } else if (type === "jobTitle") {
      setJobTitle({ ...jobTitle, isEdit: true });
      setPrevData({ type, value: jobTitle.value });
    } else if (type === "languageSpoken") {
      setLanguageSpoken({ ...languageSpoken, isEdit: true });
      setPrevData({ type, value: languageSpoken.value });
    } else if (type === "phone") {
      setPhone({ ...phone, isEdit: true });
      setPrevData({ type, value: phone.value });
    } else if (type === "currency") {
      setCurrency({ ...currency, isEdit: true });
      setPrevData({ type, value: currency.value });
    } else if (type === "profileId") {
      setProfileId({ ...profileId, isEdit: true });
      setPrevData({ type, value: profileId.value });
    }
  };

  const handleCancle = (type) => {
    if (type === "companyName") {
      setCompanyName({ ...companyName, isEdit: false, value: prevdata.value });
    } else if (type === "accountType") {
      setAccoutType({ ...accountType, isEdit: false, value: prevdata.value });
    } else if (type === "businessLocation") {
      setBusinessLocation({ ...businessLocation, isEdit: false, value: prevdata.value });
    } else if (type === "city") {
      setCity({ ...city, isEdit: false, value: prevdata.value });
    } else if (type === "streetAddress") {
      setStreetAddress({ ...streetAddress, isEdit: false, value: prevdata.value });
    } else if (type === "postalCode") {
      setPostCode({ ...postalCode, isEdit: false, value: prevdata.value });
    } else if (type === "webSite") {
      setWebSite({ ...webSite, isEdit: false, value: prevdata.value });
    } else if (type === "about") {
      setAbout({ ...about, isEdit: false, value: prevdata.value });
    } else if (type === "companyId") {
      setCompanyId({ ...companyId, isEdit: false, value: prevdata.value });
    } else if (type === "firstName") {
      setFirstName({ ...firstName, isEdit: false, value: prevdata.value });
    } else if (type === "lastName") {
      setLastName({ ...lastName, isEdit: false, value: prevdata.value });
    } else if (type === "country") {
      setCountry({ ...country, isEdit: false, value: prevdata.value });
    } else if (type === "department") {
      setDepartment({ ...department, isEdit: false, value: prevdata.value });
    } else if (type === "jobTitle") {
      setJobTitle({ ...jobTitle, isEdit: false, value: prevdata.value });
    } else if (type === "languageSpoken") {
      setLanguageSpoken({ ...languageSpoken, isEdit: false, value: prevdata.value });
    } else if (type === "phone") {
      setPhone({ ...phone, isEdit: false, value: prevdata.value });
    } else if (type === "currency") {
      setCurrency({ ...currency, isEdit: false, value: prevdata.value });
    } else if (type === "profileId") {
      setProfileId({ ...profileId, isEdit: false, value: prevdata.value });
    }
  };

  const handleSave = (type) => {
    //Api call to save data
    if (type === "companyName") {
      setCompanyName({ ...companyName, isEdit: false, value: companyName.value });
    } else if (type === "accountType") {
      setAccoutType({ ...accountType, isEdit: false, value: accountType.value });
    } else if (type === "businessLocation") {
      setBusinessLocation({ ...businessLocation, isEdit: false, value: businessLocation.value });
    } else if (type === "city") {
      setCity({ ...city, isEdit: false, value: city.value });
    } else if (type === "streetAddress") {
      setStreetAddress({ ...streetAddress, isEdit: false, value: streetAddress.value });
    } else if (type === "postalCode") {
      setPostCode({ ...postalCode, isEdit: false, value: postalCode.value });
    } else if (type === "webSite") {
      setWebSite({ ...webSite, isEdit: false, value: webSite.value });
    } else if (type === "about") {
      setAbout({ ...about, isEdit: false, value: about.value });
    } else if (type === "companyId") {
      setCompanyId({ ...companyId, isEdit: false, value: companyId.value });
    } else if (type === "firstName") {
      setFirstName({ ...firstName, isEdit: false, value: firstName.value });
    } else if (type === "lastName") {
      setLastName({ ...lastName, isEdit: false, value: lastName.value });
    } else if (type === "country") {
      setCountry({ ...country, isEdit: false, value: country.value });
    } else if (type === "department") {
      setDepartment({ ...department, isEdit: false, value: department.value });
    } else if (type === "jobTitle") {
      setJobTitle({ ...jobTitle, isEdit: false, value: jobTitle.value });
    } else if (type === "languageSpoken") {
      setLanguageSpoken({ ...languageSpoken, isEdit: false, value: languageSpoken.value });
    } else if (type === "phone") {
      setPhone({ ...phone, isEdit: false, value: phone.value });
    } else if (type === "currency") {
      setCurrency({ ...currency, isEdit: false, value: currency.value });
    } else if (type === "profileId") {
      setProfileId({ ...profileId, isEdit: false, value: profileId.value });
    }
  };

  const handleChange = (name, value) => {
    if (name === "newMessage") {
      setNewMessage(value);
    } else {
      setNewShipping(value);
    }
  };
  const decoded = jwt_decode(localStorage.jwtToken);
  console.log(">>>>>>>>>>>>>>>>>> decoded", decoded);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.signUpValidation[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handlePassword = (name, value) => {
    console.log(name, value);
    customValidations(name, value);
    if (name === "password") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setNewConfirmPassword(value);
    }
  };

  const handleChangePassword = () => {
    // API call for chnage password
    console.log(">>>>>>>> new Password", newPassword);
    console.log(">>>>>>>> confirm password", newConfirmPassword);
    let errorLengthPassword = customValidations("password", newPassword);
    let errorLengthConfirmPassword = customValidations("confirmPassword", newConfirmPassword);
    // if (!validator.isStrongPassword(newPassword)) {
    //   swal(
    //     "Need Strong Password",
    //     "Minimum Length: 8 AlphaNumCharacters (1 Uppercase, 1 Lowercase, and 1 Symbol)",
    //     "error"
    //   );
    //   return;
    // }

    if (errorLengthPassword !== 1 && errorLengthConfirmPassword !== 1) {
      console.log("Alll okkkkkkk");
    } else {
      console.log(errorLengthPassword, errorLengthConfirmPassword, "passord errrrorrrrr");
      return;
    }

    if (newPassword !== newConfirmPassword) {
      swal("Try again", "New password & confirm password did not match", "error");
      return;
    }

    changePassword({ password: newPassword, cpassword: newConfirmPassword })
      .then((response) => {
        console.log(">>>>>>>>>>>> password chnaged");
        swal("Password changed successfully", "success");
      })
      .catch((error) => {
        swal("Something went wrong", "Try again", "error");
      });
  };

  // const handlePassword = async () => {
  //   if (newPassword === confirmPassword) {
  //     let passData = {
  //       password: newPassword,
  //       cpassword: confirmPassword,
  //     };
  //     await changePassword(passData).then((response) => {
  //       console.log(response);
  //     });
  //   } else {
  //     console.log("password match error?????????????????????");
  //   }
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        <Card sx={{ marginBottom: "1rem", overflow: "visible" }}>
          <VuiBox sx={{ display: "flex" }}>
            <VuiBox>
              <img src={Avatar} height="40rem" />
            </VuiBox>
            <VuiBox
              sx={{
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <VuiTypography color="white" fontSize="1rem">
                Shipper Name
              </VuiTypography>
              <VuiTypography color="white" fontSize="1rem">
                Shipper
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </Card>
        <Card sx={{ marginBottom: "1rem" }}>
          <VuiBox>
            <VuiBox sx={{ marginBottom: "1rem" }}>
              <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
                COMPANY INFORMATION
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "visible",

                // gridTemplateColumns: "25% auto",
              }}
            >
              <VuiBox
                sx={{
                  display: "grid",
                  gridTemplateColumns: "30% auto",

                  backgroundColor: "orange",
                  alignItems: "center",

                  // gridTemplateColumns: "25% auto",
                }}
              >
                <VuiBox item>
                  <VuiTypography color="white" fontWeight="bold" fontSize="0.8rem">
                    COMPANY NAME
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {companyName.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox width="50%">
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Company name"}
                          value={companyName.value}
                          onChange={(e) =>
                            setCompanyName({ ...companyName, value: e.target.value })
                          }
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("companyName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("companyName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {companyName.value ? companyName.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("companyName")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>

                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    ACCOUNT TYPE
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {accountType.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Account Type"}
                          value={accountType.value}
                          maxLength="10"
                          onChange={(e) => setAccoutType({ ...accountType, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("accountType")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("accountType")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {accountType.value ? accountType.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("accountType")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    BUSINESS LOCATION
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {businessLocation.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Business Location"}
                          value={businessLocation.value}
                          maxLength="10"
                          onChange={(e) =>
                            setBusinessLocation({ ...businessLocation, value: e.target.value })
                          }
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("businessLocation")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("businessLocation")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {businessLocation.value ? businessLocation.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("businessLocation")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    CITY
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {city.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"City"}
                          value={city.value}
                          maxLength="10"
                          onChange={(e) => setCity({ ...city, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("city")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("city")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {city.value ? city.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("city")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    STREET ADDRESS
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {streetAddress.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Street Address"}
                          value={streetAddress.value}
                          maxLength="10"
                          onChange={(e) =>
                            setStreetAddress({ ...streetAddress, value: e.target.value })
                          }
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("streetAddress")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("streetAddress")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {streetAddress.value ? streetAddress.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("streetAddress")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    POSTAL CODE
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {postalCode.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Postal Code"}
                          value={postalCode.value}
                          maxLength="10"
                          onChange={(e) => setPostCode({ ...postalCode, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("postalCode")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("postalCode")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {postalCode.value ? postalCode.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("postalCode")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    WEB-SITE
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {webSite.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Website"}
                          value={webSite.value}
                          maxLength="10"
                          onChange={(e) => setWebSite({ ...webSite, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("webSite")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("webSite")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {webSite.value ? webSite.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("webSite")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    ABOUT
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {about.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"About"}
                          value={about.value}
                          maxLength="10"
                          onChange={(e) => setAbout({ ...about, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("about")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("about")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {about.value ? about.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("about")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    COMPANY ID
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {companyId.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Company Id"}
                          value={companyId.value}
                          maxLength="10"
                          onChange={(e) => setCompanyId({ ...companyId, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("companyId")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("companyId")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {companyId.value ? companyId.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("companyId")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>

        <Card sx={{ marginBottom: "1rem" }}>
          <VuiBox>
            <VuiBox sx={{ marginBottom: "1rem" }}>
              <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
                PROFILE INFORMATION
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "visible",

                // gridTemplateColumns: "25% auto",
              }}
            >
              <VuiBox
                sx={{
                  display: "grid",
                  gridTemplateColumns: "30% auto",

                  backgroundColor: "orange",
                  alignItems: "center",

                  // gridTemplateColumns: "25% auto",
                }}
              >
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    ID
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    {decoded.id}
                  </VuiTypography>
                </VuiBox>

                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    EMAIL
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    {decoded.email}
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    FIRST NAME
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {firstName.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"First Name"}
                          value={firstName.value}
                          maxLength="10"
                          onChange={(e) => setFirstName({ ...firstName, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("firstName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("firstName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {firstName.value ? firstName.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("firstName")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    LAST NAME
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {lastName.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Last Name"}
                          value={lastName.value}
                          maxLength="10"
                          onChange={(e) => setLastName({ ...lastName, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("lastName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("lastName")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {lastName.value ? lastName.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("lastName")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    COUNTRY
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {country.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Country"}
                          value={streetAddress.value}
                          maxLength="10"
                          onChange={(e) => setCountry({ ...country, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("country")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("country")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {country.value ? country.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("country")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    DEPARTMENT
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {department.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Department"}
                          value={department.value}
                          maxLength="10"
                          onChange={(e) => setPostCode({ ...department, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("department")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("department")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {department.value ? department.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("postalCode")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    JOB-TITLE
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {jobTitle.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Website"}
                          value={webSite.value}
                          maxLength="10"
                          onChange={(e) => setJobTitle({ ...jobTitle, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("jobTitle")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("jobTitle")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {jobTitle.value ? jobTitle.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("jobTitle")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    LANGUAGE SPOKEN
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {languageSpoken.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"About"}
                          value={about.value}
                          maxLength="10"
                          onChange={(e) =>
                            setLanguageSpoken({ ...languageSpoken, value: e.target.value })
                          }
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("languageSpoken")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("languageSpoken")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {about.value ? about.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("languageSpoken")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    PHONE
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {phone.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Phone"}
                          value={phone.value}
                          maxLength="10"
                          onChange={(e) => setPhone({ ...phone, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("phone")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("phone")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {phone.value ? phone.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("phone")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox item>
                  <VuiTypography color="white" fontSize="0.8rem">
                    CURRENCY
                  </VuiTypography>
                </VuiBox>
                <VuiBox item>
                  {currency.isEdit ? (
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBox>
                        <VuiInput
                          inputProps={{ type: "string" }}
                          defaultValue={""}
                          sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                          })}
                          placeholder={"Phone"}
                          value={phone.value}
                          maxLength="10"
                          onChange={(e) => setCurrency({ ...currency, value: e.target.value })}
                        />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiButton variant="text" onClick={() => handleSave("currency")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Save
                          </VuiTypography>
                        </VuiButton>
                        <VuiButton variant="text" onClick={() => handleCancle("currency")}>
                          <VuiTypography color="white" fontSize="0.8rem">
                            Cancel
                          </VuiTypography>
                        </VuiButton>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <VuiBox>
                        <VuiTypography color="white" fontSize="0.8rem">
                          {currency.value ? currency.value : "Empty"}
                        </VuiTypography>
                      </VuiBox>

                      <VuiButton variant="text" onClick={() => handleEditData("currency")}>
                        <VuiTypography fontSize="0.8rem">Edit</VuiTypography>
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>
        <Card sx={{ marginBottom: "1rem", overflow: "visible" }}>
          <VuiBox>
            {/* <VuiBox sx={{ marginBottom: "1rem" }}>
              <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
                CHANGE PASSWORD
              </VuiTypography>
            </VuiBox>
            <VuiBox sx={{ width: "50%" }}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  CURRENT PASSWORD
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "password" }}
                defaultValue={""}
                // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                // })}
                placeholder={"CURRENT PASSWORD"}
                value={""}
                // onChange={}
              />
            </VuiBox> */}

            <VuiBox sx={{ width: "50%" }}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  NEW PASSWORD
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="password"
                inputProps={{ type: "password" }}
                // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                // })}
                placeholder={"Enter new password"}
                value={newPassword}
                error={errorMsg.password.errorMsg.length > 0}
                onChange={(e) => handlePassword(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.password.errorMsg}
              </VuiTypography>
            </VuiBox>

            <VuiBox sx={{ width: "50%", marginBottom: "1rem" }}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  CONFIRM PASSWORD
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="confirmPassword"
                inputProps={{ type: "password" }}
                // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                // })}
                placeholder={"Enter new password again"}
                value={newConfirmPassword}
                error={errorMsg.confirmPassword.errorMsg.length > 0}
                onChange={(e) => handlePassword(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.confirmPassword.errorMsg}
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiButton variant="gradient" color="info" onClick={handleChangePassword}>
                Change Password
              </VuiButton>
            </VuiBox>
          </VuiBox>
        </Card>
        <Card sx={{ overflow: "visible" }}>
          <VuiBox>
            <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
              EMAIL NOTIFICATION
            </VuiTypography>
            <VuiBox>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newMessage}
                        onChange={(e) => handleChange("newMessage", e.target.checked)}
                        name="newMessage"
                      />
                    }
                    label={
                      <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
                        Notify me when new messages arrive
                      </VuiTypography>
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newShipping}
                        onChange={(e) => handleChange("newShipping", e.target.checked)}
                        name="newShipping"
                      />
                    }
                    label={
                      <VuiTypography color="white" fontWeight="bold" fontSize="1rem">
                        Notify me when new shipping lead posted
                      </VuiTypography>
                    }
                  />
                </FormGroup>
                {/* <FormHelperText>Be careful</FormHelperText> */}
              </FormControl>
            </VuiBox>
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
};

export default Profile;
