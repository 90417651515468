import { useState } from "react";

// react-router-dom components
import { Link, useParams, Redirect } from "react-router-dom";

import swal from 'sweetalert';

import illustrationImage from "assets/images/illustration-image.png";
import{verifyUser} from 'services/userService'

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

function userVerify() {
  
  const { token } = useParams();

  if(token !=undefined && token != "" ){
      verifyUser(token).then(response => {
        console.log(response);
        if (response.success == true) {
          swal("Great", response.msg, "success").then(()=>{
            window.location.href = "";
            <Redirect from="*" to='/authentication/sign-in' />
          });
        } else {
          console.log(response.status);
          swal("Ooops", response.msg, "error").then(() => {
            window.location.href = "";
            <Redirect from="*" to='/authentication/sign-in' />
          })
        }
      })
    }
  
    return (
        <IllustrationLayout
        title="Verify User"
        description="User is getting Verify..."
        illustration={{
          image: illustrationImage,
        }}
        top={20}
      >


        </IllustrationLayout>
    )
}


export default userVerify;