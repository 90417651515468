import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeLight from "assets/themeLight";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";

// Vision UI Dashboard PRO React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import GuardedRoute from "./utils/GuardedRoute";
import { logoutUser } from "./services/userService";
import jwt_decode from "jwt-decode";
import setAuthToken from "utils/setAuthToken";
import isEmpty from "utils/isEmpty";
import "utils/dateFormat";

// Component
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import userVerify from "layouts/authentication/verify";
import SignIn from "layouts/authentication/sign-in/cover";
import activateShip from "layouts/pages/ship/active-ship/activateShip";
import activateLclContainer from "layouts/pages/container/activate-lcl-container";
import activateFclContainer from "layouts/pages/container/activate-fcl-container";
import ActiveShipDetails from "layouts/pages/ship/active-ship/active-ship-details";
import ActiveContainerDetails from "layouts/pages/container/active-container-details/index.js";
import ApproveRequest from "layouts/pages/quotation/ship-pending-quoatation/approve-request";
import ApproveLCLRequest from "layouts/pages/container/LCL-container-pending-quoatation/approvelclcontainer.js";
import ApproverFclRequest from "layouts/pages/container/FCL-container-pending-quoatation/aprrovefclcontainer.js";
import ShipQuoatationDetails from "layouts/pages/quotation/ship-quoatation/ship-quoatation-details";
import LCLQuoatationDetails from "layouts/pages/container/LCL-container-quoatation/lcl-container-details";
import FCLQuoatationDetails from "layouts/pages/container/FCL-container-quoatation/fcl-container-details";
import Profile from "layouts/Profile/index.js";
import BillOfLading from "layouts/pages/smart-document/bill-of-lading";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, themeMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [themeSetter, setThemeSetter] = useState(theme);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    routes.push({
      name: "Activate Ships",
      key: "activate-ships",
      route: "/merchant/activate-ships/:mmsikey",
      component: activateShip,
    });
    routes.push({
      name: "Activate LCL Containers",
      key: "activate-lcl-container",
      route: "/merchant/activate-lcl-container/:containerKey",
      component: activateLclContainer,
    });
    routes.push({
      name: "Activate FCL Containers",
      key: "activate-fcl-container",
      route: "/merchant/activate-fcl-container/:containerKey",
      component: activateFclContainer,
    });
    routes.push({
      name: "Active Ship Details",
      key: "active-ship-details",
      route: "/merchant/active-ship-details",
      component: ActiveShipDetails,
    });
    routes.push({
      name: "Active Container Details",
      key: "active-container-details",
      route: "/merchant/active-container-details",
      component: ActiveContainerDetails,
    });
    routes.push({
      name: "Ship Pending Quotation Details",
      key: "ship-pending-quoatation-details",
      route: "/merchant/ship-pending-quotation-details",
      component: ApproveRequest,
    });
    routes.push({
      name: "Lcl Container Pending Quotation Details",
      key: "lcl-container-pending-quoatation-details",
      route: "/merchant/lcl-container-pending-quotation-details",
      component: ApproveLCLRequest,
    });
    routes.push({
      name: "Fcl Container Pending Quotation Details",
      key: "Fcl-container-pending-quoatation-details",
      route: "/merchant/fcl-container-pending-quotation-details",
      component: ApproverFclRequest,
    });
    routes.push({
      name: "List of Book Ship",
      key: "list-of-book-ship",
      route: "/merchant/active-ship-quotation-details",
      component: ShipQuoatationDetails,
    });
    routes.push({
      name: "Booked LCL Container Details",
      key: "booked-lcl-container-details",
      route: "/merchant/lcl-container-quotation-details",
      component: LCLQuoatationDetails,
    });
    routes.push({
      name: "Booked FCL Container Details",
      key: "booked-fcl-container-details",
      route: "/merchant/fcl-container-quotation-details",
      component: FCLQuoatationDetails,
    });
    routes.push({
      name: "Profile",
      key: "profile",
      route: "/merchant/profile",
      component: Profile,
    });
    routes.push({
      name: "BillOfLading",
      key: "BillOfLading",
      route: "/merchant/BillOfLading",
      component: BillOfLading,
    });
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      setIsAuthenticated(true);
      // Check for expired token
      // setInterval(() => {
      //   const currentTime = Date.now() / 1000;
      //   if (decoded.iat < currentTime || isEmpty(localStorage.getItem("jwtToken"))) {
      //     setIsAuthenticated(false);
      //     localStorage.removeItem("jwtToken");
      //     setAuthToken(false);
      //   }
      // }, 600000);
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    // allRoutes.push({name: "Activate Ship",key: "activate-ship", route: "/merchant/activate-ship/:mmsikey", component: activateShip})
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <GuardedRoute
            exact
            path={route.route}
            component={route.component}
            key={route.key}
            auth={isAuthenticated}
          />
        );
      }

      return null;
    });

  useEffect(() => {
    if (themeMode === false) {
      setThemeSetter(themeLight);
    } else {
      setThemeSetter(theme);
    }
  }, [themeMode]);

  console.log(themeMode);

  return (
    <ThemeProvider theme={themeSetter}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="BitNautic Merchant"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <Switch>
        {isAuthenticated ? (
          getRoutes(routes)
        ) : (
          <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
        )}
        {isAuthenticated ? (
          ""
        ) : (
          <Route path={"/authentication/sign-up"} component={SignUpIllustration} key={"register"} />
        )}
        {isAuthenticated ? (
          ""
        ) : (
          <Route
            path={"/authentication/verify-user/:token"}
            component={userVerify}
            key={"verify"}
          />
        )}
        {isAuthenticated ? (
          <Redirect from="*" to="/merchant/dashboard" />
        ) : (
          <Redirect from="*" to="/authentication/sign-in" />
        )}
      </Switch>
    </ThemeProvider>
  );
}
