// @mui material components
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import { getInActiveShips } from "services/shipService";

// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
function GetInActiveShips() {
  useEffect(async () => {
    await fetchInactiveDetails();
  }, []);

  const [inactiveShipData, setInactiveShipData] = useState("");

  const fetchInactiveDetails = () => {
    getInActiveShips().then((Response) => {
      setInactiveShipData(Response);
      console.log(">>>>>>>>>>> details", Response);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const dataTableData = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey", width: "20%" },
      // { Header: "IMO", accessor: "imo", width: "5%" },
      { Header: "Name", accessor: "name", align: "center" },
      { Header: "Current Port", accessor: "homePort", align: "center" },
      {
        Header: "Year Build",
        accessor: "yearBuild",
        align: "center",
        Cell: (cell) => (
          <VuiTypography variant="sm" color="text">
            {dates(cell.row.values.yearBuild)}
          </VuiTypography>
        ),
      },
      { Header: "Ship Type", accessor: "shipType", align: "center" },
      {
        Header: "Change Ship Status",
        accessor: "mmsiKey",
        Cell: (cell) => (
          <Link to={`/merchant/activate-ships/${cell.row.values.mmsiKey}`}>
            <VuiButton variant="gradient" color="info">
              Activate
            </VuiButton>
          </Link>
        ),
      },
    ],

    rows: inactiveShipData ? inactiveShipData : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              In Active Ships
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all InActive which are Not ready to shipped.
            </VuiTypography>
          </VuiBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GetInActiveShips;
