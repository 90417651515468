import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { FclContainerPendingQuoatation } from "services/containerService";

function FCLContainerPendingQuoatation() {
  let history = useHistory();
  useEffect(async()=>{
    await handleGetFCLPendingQuoatation()
  },[])

  const [fclPendingQuoatation, setFclPendingQuoatation] = useState("");

  const handleGetFCLPendingQuoatation = () => {
    FclContainerPendingQuoatation().then(Response => {
      console.log(">>>>>>>>>>>>>>> FCL Pending Quoatation", Response)
      setFclPendingQuoatation(Response)
    })
  }

  const dates = (time)=>{
    var d = new Date(time);
    var time =d.getUTCMonth()+1+" / "+d.getDate()+" / "+d.getUTCFullYear() 
    return time
  }

  const datesWithTime = (time)=>{
    var d = new Date(time);
    var time = d.getDate()+" / "+ (d.getUTCMonth()+1)+" / "+d.getUTCFullYear()+ " "+ d.getHours() +":"+ d.getMinutes()
    return time
  }

  const showFclContainerDetails = (id) => {
    console.log(id);
    history.push({
      pathname: "/merchant/fcl-container-pending-quotation-details",
      details: id,
    });
  };

  const fclPendingQuoatationData = {
    columns: [
      { Header: "Shipper Id", accessor: "shipperID", },
      { Header: "Container Key", accessor: "containerKey"},
      { Header: "Invoice Id", accessor: "invoiceID"},
      { Header: "Invoice Status", accessor: "status"},
      { Header: "Service Provider", accessor: "serviceProviderID"},
      { Header: "Action", Cell: (cell) => (
        <VuiButton variant="gradient" color="info" size="medium" onClick={() => showFclContainerDetails(cell.row.original)}>More Details</VuiButton>
      ) },
    ],

    rows: fclPendingQuoatation? fclPendingQuoatation: [],
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
            FCL Container Pending Quotation
            </VuiTypography>
            {/* <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all Container which are ready to shipped.
            </VuiTypography> */}
          </VuiBox>
          <DataTable table={fclPendingQuoatationData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FCLContainerPendingQuoatation;
