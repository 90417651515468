
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

import{createShip} from 'services/shipService';


function ChangePassword() {
  const [skills, setSkills] = useState(["react", "angular"]);

  const [mmsiKey, setMmsiKey ] = useState("");
  const [imo, setImo] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [yearBuild, setYearBuild] = useState("");
  const [homePort, setHomePort] = useState("");
  const [callSign, setCallSign] = useState("");
  const [shipFlag, setShipFlag] = useState("");
  const [deadWeight, setDeadWeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [shipType, setShipType] = useState("");
  // const [containerType, setContainerType] = useState("");
  const [standard20, setStandard20] = useState("");
  const [standard40, setStandard40] = useState("");
  const [highcube40, setHighcube40] = useState("");
  const [refrigerated20, setRefrigerated20] = useState("");
  const [refrigerated40, setRefrigerated40] = useState("");
  const [higcube45, setHigcube45] = useState("");
  // const [weightMT, setWeightMT] = useState("");
  // const [volumeM, setVolumeM] = useState("");
  // const [grossWeightMT, setGrossWeightMT] = useState("");


  function handleDeposit() {
    console.log(
      {
        "mmsiKey": mmsiKey,
        "imo": imo,
        "name":name,
        "image": image,
        "yearBuild": yearBuild,
        "homePort":homePort,
        "callSign":callSign,
        "shipFlag":shipFlag,
        "deadWeight":deadWeight,
        "capacity":capacity,
        "shipType":shipType,
        // "containerType":containerType,
        "standard20":standard20,
        "standard40":standard40,
        "highcube40":highcube40,
        "refrigerated20":refrigerated20,
        "refrigerated40":refrigerated40,
        "higcube45":higcube45,
        // "weightMT":weightMT,
        // "volumeM":volumeM,
        // "grossWeightMT":grossWeightMT
      }
    );
    if (mmsiKey == "" && imo=="" && name=="" && image=="" && yearBuild=="" && homePort=="" && callSign=="" && shipFlag=="" &&
    deadWeight=="" && capacity=="" && shipType=="") {
      swal("","All Fields are required", "error");
      return;
    }
    // if (!validator.isEmail(email)) {
    //   swal("","Enter a correct email address", "error");
    //   return;
    // }
    // if (password != cpassword) {
    //   swal("","Confirm Password doesn't match", "error");
    //   return;
    // }
    // if (!validator.isStrongPassword(cpassword)) {
    //   swal("Need Strong Password","Minimum Length: 8 AlphaNumCharacters (1 Uppercase, 1 Lowercase, and 1 Symbol)", "error");
    //   return;
    // }
    const userData = {
      "mmsiKey": mmsiKey,
        "imo": imo,
        "name":name,
        "image": "https://qw1.io",
        "yearBuild": yearBuild,
        "homePort":homePort,
        "callSign":callSign,
        "shipFlag":shipFlag,
        "deadWeight":deadWeight,
        "capacity":capacity,
        "shipType":shipType,
        // "containerType":containerType,
        "standard20": standard20,
        "standard40": standard40,
        "highcube40": highcube40,
        "refrigerated20": refrigerated20,
        "refrigerated40": refrigerated40,
        "higcube45": higcube45,
        // "weightMT": weightMT,
        // "volumeM": volumeM,
        // "grossWeightMT": grossWeightMT

    }
    createShip(userData).then(response => {
      if(response.success){
        swal("Great!", " You have successfully Add ship!", "success");
      } else {
        swal("",response.message, "error");
      }
    })
}

    return (
      <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Add Ship
        </VuiTypography>
      </VuiBox>
      <VuiBox component="form">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                MMSI Key
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={mmsiKey} 
             onChange={e => setMmsiKey(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                IMO
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={imo} 
              onChange={e => setImo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField 
            label="Name" 
            placeholder="Name of the Ship"
            value={name} 
            onChange={e => setName(e.target.value)}
             />
          </Grid>

          
          <Grid item xs={12} sm={2}>
            <VuiTypography  variant="button"color="white" fontWeight="regular">
               Year Build
            </VuiTypography>
            <VuiDatePicker label= "Year Build" input={{ placeholder: "Select a date" }} onChange={e => setYearBuild(e[0])}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField 
            label="Home Port" 
            placeholder="Add City, Port, Country"
            value={homePort} 
            onChange={e => setHomePort(e.target.value)}
             />
          </Grid>
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
              Call Sign
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={callSign} 
             onChange={e => setCallSign(e.target.value)}
            />
          </Grid>
           
         
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
              Ship Flag
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={shipFlag}
              onChange={e => setShipFlag(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
              Dead Weight
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={deadWeight}
              onChange={e => setDeadWeight(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
              Capacity
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={capacity}
            onChange={e => setCapacity(e.target.value)}
            />
          </Grid>

        
          <Grid item xs={12} sm={12}>
          <VuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      color="white"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >Ship Type
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect 
                  placeholder="Select Ship Type" 
                  options={selectData.ship} 
                  value={selectData.ship.value}
                  onChange={e => setShipType(e.value)}
                  />
          </VuiBox>
          </Grid>
          
          <Grid item xs={12} sm={12}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      color="white"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >Container Type
                    </VuiTypography>
                  </VuiBox>
          </Grid>

          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                Ship Standard-20
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={standard20} 
              onChange={e => setStandard20(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                standard40
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={standard40} 
              onChange={e => setStandard40(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                Highcube40
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={highcube40} 
              onChange={e => setHighcube40(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                higcube45
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={higcube45} 
              onChange={e => setHigcube45(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                Refrigerated20
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={refrigerated20} 
              onChange={e => setRefrigerated20(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
               component="label" 
               variant="caption"
               fontWeight="bold"
               color="white">
                refrigerated40
              </VuiTypography>
            </VuiBox>
            <VuiInput
              inputProps={{ type: "number" }}
              defaultValue={1}
              sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
              })}
              value={refrigerated40} 
              onChange={e => setRefrigerated40(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
          <VuiButton
            onClick={handleDeposit}
            variant="contained"
            color="info"
            sx={{ minWidth: "100px" }}
          >Submit
          </VuiButton>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
    );
  };


export default ChangePassword;
