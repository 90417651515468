import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getShipQuoatation } from "services/quoatationService";

function ShipQuoatation() {
  let history = useHistory();
  useEffect(async()=>{
    await handleGetShipQuoatation()
  },[])

  const [shipQuoatation, setshipQuoatation] = useState("");

  const handleGetShipQuoatation = () => {
    getShipQuoatation().then(response => {
      console.log(">>>>>>>>>>>>>>>>>> Ship Quoatation",response );
      // let newResponse = []
      // for(let item of response)
      //    {
      //      newResponse.push({...item,departureTime: getTime(item.departureTime),arrivalTime: getTime(item.arrivalTime)})
      //    }
      setshipQuoatation(response);
    })
  }

  const dates = (time)=>{
    var d = new Date(time);
    var time =d.getUTCMonth()+1+" / "+d.getDate()+" / "+d.getUTCFullYear() 
    return time
  }

  const datesWithTime = (time)=>{
    var d = new Date(time);
    var time = d.getDate()+" / "+ (d.getUTCMonth()+1)+" / "+d.getUTCFullYear()+ " "+ d.getHours() +":"+ d.getMinutes()
    return time
  }

  const getTime = (time)=>{
    var d = new Date(time);
    var time = d.getHours() +":"+ d.getMinutes()
    return time
  }

  const showShipDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/merchant/active-ship-quotation-details",
      details: data,
    });
  };

  const shipQuoatationData = {
    columns: [
      { Header: "MMSI Key", accessor: "shipMmsiKey", },
      { Header: "Departure Time", accessor: "departureTime",  Cell: (cell) => (
        <VuiTypography variant="gradient" fontWeight="medium" color="white">{getTime(cell.row.values.departureTime)}</VuiTypography>
      )},
      { Header: "Arrival Time", accessor: "arrivalTime",  Cell: (cell) => (
        <VuiTypography variant="gradient" fontWeight="medium" color="white">{getTime(cell.row.values.arrivalTime)}</VuiTypography>
      )},
      { Header: "Current Port", accessor: "currentPort"},
      { Header: "Destination Port", accessor: "destinationPort"},
      { Header: "Action", Cell: (cell) => (
        <VuiButton variant="gradient" color="info" size="medium" onClick={() => showShipDetail(cell.row.original)}>More Details</VuiButton>
      ) },
    ],

    rows: shipQuoatation ? shipQuoatation: [],
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
             Ship Quotation
            </VuiTypography>
            {/* <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all Container which are ready to shipped.
            </VuiTypography> */}
          </VuiBox>
          <DataTable table={shipQuoatationData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ShipQuoatation;
