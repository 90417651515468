import { useState } from "react";
import { Link, Redirect, Route } from "react-router-dom";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiTagInput from "components/VuiTagInput";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiDatePicker from "components/VuiDatePicker";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Service
import { createContainer } from "services/containerService";

import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";

function AddContainer() {
  const [containerKey, setcontainerKey] = useState("");
  const [containerName, setcontainerName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [height, setHeight] = useState("");
  const [buildYear, setbuildYear] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    containerKey: { errorMsg: "" },
    containerName: { errorMsg: "" },
    buildYear: { errorMsg: "" },
    capacity: { errorMsg: "" },
    width: { errorMsg: "" },
    length: { errorMsg: "" },
    height: { errorMsg: "" },
  });

  // const handleChange = (prop) => (event) => {
  //     console.log(values);
  //     setValues({
  //         ...values,
  //         [prop]: event.target.value
  //     });
  // };

  const handleAddContainer = () => {
    console.log({
      containerKey: containerKey,
      name: containerName,
      capacity: capacity,
      width: width,
      length: length,
      height: height,
      yearBuild: buildYear,
    });

    let errorLengthKey = customValidations("containerKey", containerKey);
    let errorLengthName = customValidations("containerName", containerName);
    let errorLengthWidth = customValidations("width", width);
    let errorLengthLength = customValidations("length", length);
    let errorLengthHeight = customValidations("height", height);
    let errorLengthCapacity = customValidations("capacity", capacity);
    let errorLengthbuildYear = customValidations("buildYear", buildYear);

    if (
      errorLengthKey !== 1 &&
      errorLengthName !== 1 &&
      errorLengthWidth !== 1 &&
      errorLengthLength !== 1 &&
      errorLengthHeight !== 1 &&
      errorLengthCapacity !== 1 &&
      errorLengthbuildYear !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(errorLengthKey, "input errrrrrorrrr");
      return;
    }

    const containerData = {
      containerKey: containerKey,
      name: containerName,
      capacity: capacity,
      width: width,
      length: length,
      height: height,
      yearBuild: buildYear,
    };

    console.log(">>>>>>>>>>>>>> containerData");
    createContainer(containerData).then((response) => {
      // {"containerKey":"12345123","name":"Rahul","capacity":"123","width":"123","length":"123","height":"123","yearBuild":"2022-03-17"}
      // {"containerKey":"12345","name":"New Container","capacity":"01","width":"11","length":"2","height":"1","yearBuild":"2022-03-03"}
      if (response.msg.includes("successfully")) {
        swal("Great!", "Container added successfully", "success").then(() => {
          window.location = "#/merchant/inactive-conatiners";
        });
      } else if (response.msg.includes("already")) {
        swal(``, `Ship with Container Key ${containerKey} already exists`, "error");
      }
    });
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.addContainerValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        console.log("thisoneeeee");
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        console.log("thisoneeeee");
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "containerKey") {
      setcontainerKey(value);
    } else if (name === "containerName") {
      setcontainerName(value);
    } else if (name === "capacity") {
      setCapacity(value);
    } else if (name === "width") {
      setWidth(value);
    } else if (name === "length") {
      setLength(value);
    } else if (name === "height") {
      setHeight(value);
    } else if (name === "buildYear") {
      setbuildYear(value);
    } else {
      console.log("ERRRORRRRRRRRR");
    }
    customValidations(name, value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="Add Container" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Add Container
          </VuiTypography>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Container Key
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required={true}
                value={containerKey}
                name="containerKey"
                error={errorMsg.containerKey.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.containerKey.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Container Name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "string" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required={true}
                value={containerName}
                name="containerName"
                error={errorMsg.containerName.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.containerName.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Capacity
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required
                value={capacity}
                name="capacity"
                error={errorMsg.capacity.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />

              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.capacity.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Width
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required={true}
                value={width}
                name="width"
                error={errorMsg.width.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.width.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Length
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required={true}
                value={length}
                name="length"
                error={errorMsg.length.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.length.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Height
                </VuiTypography>
              </VuiBox>
              <VuiInput
                inputProps={{ type: "number" }}
                // defaultValue={1}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                // required={true}
                value={height}
                name="height"
                error={errorMsg.height.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.height.errorMsg}
              </VuiTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {/* <FormField
                label="Year of Build"
                placeholder="Select Year of Build"
                inputProps={{ type: "date" }}
                onChange={(e) => handleChangeInput("buildYear", e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.buildYear.errorMsg}
              </VuiTypography> */}
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="white">
                  Year Build
                </VuiTypography>
              </VuiBox>
              <VuiDatePicker
                label="Year Build"
                input={{ placeholder: "Select a Build Year" }}
                // onChange={(e) => setYearBuild(e[0])}
                error={errorMsg.buildYear.errorMsg.length > 0}
                options={{ enableTime: true, minDate: new Date() }}
                onChange={(e) => handleChangeInput("buildYear", e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.buildYear.errorMsg}
              </VuiTypography>
              {console.log(buildYear)}
            </Grid>
            <Grid item xs={12} lg={6} mt={"1rem"}>
              <VuiButton
                variant="gradient"
                color="info"
                size="large"
                fontSize="16px"
                onClick={handleAddContainer}
              >
                Add Container
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  );
}

export default AddContainer;
