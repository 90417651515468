import { useState } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import { getPorts, activateShip } from "services/shipService";
import Validations from "../../../../shared/validations";
import ValidationService from "../../../../shared/validationService";
import swal from "sweetalert";

function ActivateShip() {
  const [errorMsg, setErrorMsg] = useState({
    category: { errorMsg: "" },
    currentPort: { errorMsg: "" },
    departureTime: { errorMsg: "" },
    arrivalTime: { errorMsg: "" },
    readyToLoad: { errorMsg: "" },
    destinationPort: { errorMsg: "" },
    shipPrice: { errorMsg: "" },
    shipStopPorts: { errorMsg: "" },
  });
  const [Category, setCategory] = useState("");
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [CurrentPort, setCurrentPort] = useState([]);
  const [DepartureTime, setDepartureTime] = useState("");
  const [ArrivalTime, setArrivalTime] = useState("");
  const [ReadyToLoad, setReadyToLoad] = useState("");
  const [DestinationPort, setDestinationPort] = useState("");
  const [ShipPrice, setShipPrice] = useState("");
  const [shipDetails, setShipDetails] = useState("");
  const [ShipStopPorts, setShipStopPorts] = useState([]);
  const [shipLocation, setShipLocation] = useState([]);
  const [tags, setTags] = useState([""]);

  const { mmsikey } = useParams();

  function handleActivateShip() {
    setShipDetails(mmsikey);
    // {"category":"w","originPort":"Charlotte","departureTime":"2022-02-25T00:29","destinationPort":"Kipindi","arrivalTime":"2022-02-25T07:33","ports":["Watsi-Genge","Charlotte","Hartsdale","Ipole"],"shipLocation":["-0.91087,20.6897","53.24733,-132.0307","40.71213,-73.9963","-3.83567,18.91101"],"readyToLoad":"2022-02-16","currentPort":"Charlotte","status":"Ready to Load","shipDetails":"5","shipPrice":"123","shipStatus":"active"}
    console.log({
      category: Category,
      originPort: CurrentPort,
      departureTime: DepartureTime,
      arrivalTime: ArrivalTime,
      readyToLoad: ReadyToLoad,
      currentPort: CurrentPort,
      destinationPort: DestinationPort,
      ports: ShipStopPorts,
      shipLocation: shipLocation,
      status: "Ready to Load",
      shipDetails: mmsikey,
      shipPrice: ShipPrice,
      shipStatus: "active",
    });

    let errorLengthCategory = customValidations("category", Category);
    let errorLengthCurrentPort = customValidations("currentPort", CurrentPort);
    let errorLengthDestinationPort = customValidations("destinationPort", DestinationPort);
    let errorLengthReadyToLoad = customValidations("readyToLoad", ReadyToLoad);
    let errorLengthDepartureTime = customValidations("departureTime", DepartureTime);
    let errorLengthArrivalTime = customValidations("arrivalTime", ArrivalTime);
    let errorLengthShipPrice = customValidations("shipPrice", ShipPrice);
    let errorLengthShipStopPorts = customValidations("shipStopPorts", ShipStopPorts);
    if (
      errorLengthCategory !== 1 &&
      errorLengthCurrentPort !== 1 &&
      errorLengthDestinationPort !== 1 &&
      errorLengthReadyToLoad !== 1 &&
      errorLengthDepartureTime !== 1 &&
      errorLengthArrivalTime !== 1 &&
      errorLengthShipPrice !== 1 &&
      errorLengthShipStopPorts !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(errorLengthCategory);
      console.log(errorLengthCurrentPort);
      console.log("input errrrrrorrrr");

      return;
    }

    const activateShipData = {
      category: Category,
      originPort: CurrentPort,
      departureTime: DepartureTime,
      arrivalTime: ArrivalTime,
      readyToLoad: ReadyToLoad,
      currentPort: CurrentPort[0],
      destinationPort: DestinationPort[0],
      ports: ShipStopPorts,
      shipLocation: shipLocation,
      status: "Ready to Load",
      shipDetails: mmsikey,
      shipPrice: ShipPrice,
      shipStatus: "active",
    };
    activateShip(activateShipData).then((response) => {
      console.log(response, "response activate");
      if (response.success == true) {
        swal("Activated", "Ship Activated Sucessfully", "success").then(() => {
          window.location = "#/merchant/active-ships";
        });
      } else {
        swal(response.msg, "Try Again Later", "error");
      }
    });
  }
  const getMultiports = (e) => {
    const portName = [CurrentPort[0]];
    const shipLocation = [CurrentPort[1]];
    const multiPort = e.map((data) => {
      portName.push(data.portName);
      shipLocation.push([data.xcoord, data.ycoord]);
    });
    portName.push(DestinationPort[0]);
    shipLocation.push(DestinationPort[1]);
    setShipStopPorts(portName);
    setShipLocation(shipLocation);
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = [data.portName, [data.xcoord, data.ycoord]];
          return data;
        });
        setSearchedPorts(resp);
      });
    }
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.activateShipValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    customValidations(name, value);

    if (name === "category") {
      setCategory(value);
    } else if (name === "currentPort") {
      setCurrentPort(value);
    } else if (name === "departureTime") {
      setDepartureTime(value);
    } else if (name === "arrivalTime") {
      setArrivalTime(value);
    } else if (name === "readyToLoad") {
      setReadyToLoad(value);
    } else if (name === "destinationPort") {
      setDestinationPort(value);
    } else if (name === "shipPrice") {
      setShipPrice(value);
    } else if (name === "shipStopPorts") {
      console.log("stopPorts");
    } else {
      console.log("ERRRORRRRRRRRR");
    }
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <VuiBox mb="40px">
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Activate Ship
          </VuiTypography>
        </VuiBox>
        <VuiBox component="form">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Category
              </VuiTypography>
              <VuiInput
                name="category"
                placeholder="Enter Category"
                value={Category}
                error={errorMsg.category.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.category.errorMsg}
              </VuiTypography>
              {/* <FormField
                label="Category"
                placeholder="Enter the Category"
                value={Category}
                onChange={(e) => setCategory(e.target.value)}
              /> */}
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Current Port
              </VuiTypography>
              <VuiSelect
                placeholder="Select Current Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.currentPort.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("currentPort", e.value)}
                // onChange={(e) => setCurrentPort(e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.currentPort.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Ready to Load
              </VuiTypography>
              <VuiDatePicker
                label="Ready to Load"
                input={{ placeholder: "Select a date" }}
                value={ReadyToLoad}
                options={{ enableTime: true, minDate: new Date() }}
                error={errorMsg.readyToLoad.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("readyToLoad", e[0].toMysqlFormat())}
                // onChange={(e) => setReadyToLoad(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.readyToLoad.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Departure Time
              </VuiTypography>
              <VuiDatePicker
                label="Enter Departure Time"
                input={{ placeholder: "Select a date" }}
                value={DepartureTime}
                // options={{ minDate: new Date() }}
                options={{ enableTime: true, minDate: new Date(ReadyToLoad) }}
                error={errorMsg.departureTime.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("departureTime", e[0].toMysqlFormat())}
                // onChange={(e) => setDepartureTime(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.departureTime.errorMsg}
              </VuiTypography>
            </Grid>
            {console.log(ReadyToLoad)}
            {console.log(getTime(ReadyToLoad))}

            <Grid item xs={12} sm={4}>
              <VuiTypography variant="button" color="white" fontWeight="regular">
                Arrival time for Destination Port
              </VuiTypography>
              <VuiDatePicker
                label="Enter Arrival Time"
                input={{ placeholder: "Select a date" }}
                value={ArrivalTime}
                options={{
                  enableTime: true,
                  dateFormat: "Y-m-d H:i",
                  minDate: new Date(DepartureTime),
                }}
                error={errorMsg.arrivalTime.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("arrivalTime", e[0].toMysqlFormat())}
                // onChange={(e) => setArrivalTime(e[0].toMysqlFormat())}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.arrivalTime.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography variant="button" fontWeight="regular" color="white">
                  Destination Port
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                size="medium"
                fontSize="bold"
                placeholder="Select Destination Port"
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.destinationPort.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("destinationPort", e.value)}
                // onChange={(e) => setDestinationPort(e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.destinationPort.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography variant="button" fontWeight="regular" color="white">
                  Ship Price
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="shipPrice"
                placeholder="Enter Ship Price"
                icon={{
                  component: "money",
                  direction: "left",
                }}
                value={ShipPrice}
                error={errorMsg.shipPrice.errorMsg.length > 0}
                onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.shipPrice.errorMsg}
              </VuiTypography>
            </Grid>

            <Grid item xs={12} lg={12}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography variant="button" fontWeight="regular" color="white">
                  Choose Ship Stop ports
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Destination Port"
                isMulti
                options={searchedPorts}
                value={searchedPorts.value}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.shipStopPorts.errorMsg.length > 0}
                onChange={(e) => handleChangeInput("shipStopPorts", getMultiports(e))}
                // onChange={(e) => getMultiports(e)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.shipStopPorts.errorMsg}
              </VuiTypography>
            </Grid>

            {/* SUBMIT BUTTON */}
            <Grid item xs={12} sm={6} lg={6}>
              <VuiButton
                onClick={handleActivateShip}
                variant="gradient"
                color="info"
                sx={{ minWidth: "100px" }}
              >
                Activate Ship
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
    </DashboardLayout>
  );
}

export default ActivateShip;
